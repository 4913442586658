import Lang from './lang.json';
import {store} from '../store/index.js';

/**
 * Verkrijg een string uit lang.json
 * Taal wordt uit store gehaald
 *
 *
 * 
 * Notatie in lang.js:
 * 
 * "pagina-naam (bijv. TestPage)": {
        "stringID (bijv. hallo)": {
            "nl-NL": "Hallo",
            "en-EN": "Hello",
            "fr-FR": "Bonjour"
        }
    }
*/


// Nieuwe, verbeterde naageving EN
export const translate = {


    get: function(page, id) {
        // instantieer lang.js als var
        var json = Lang;


        // language uit store
        var storedLanguage = store.state.localeLanguage;

        var result;
        // resultaat ophalen
        // als de string niet bestaat, geef '' terug
        if(json[page] === undefined) {
            console.error("language error on page "+page+" "+id);
            result = 'ERROR:TRANS|PAGE';
        }
        else if(json[page][id] === undefined) {
            console.error("language error on page ID "+page+" "+id);
            result = 'ERROR:TRANS|ID';
        }
        else if(json[page][id][storedLanguage] == undefined) {
            console.error("language error on page ID Language " + page + " " + id + " " + storedLanguage);
            result = 'ERROR:TRANS|LANG';
        } else {
            result = json[page][id][storedLanguage];
        }

        return result;
    },

    getWithParams: function(page, id, objectParams) {
        var string = this.get(page, id)
        var parsedString = this.StringTemplateParser(string, objectParams)
        var replacedString = String(parsedString).replace('&quot;', '"').replace('&quot;', '"')
        return replacedString;
    },

    StringTemplateParser: function(expression, valueObj) {
        const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
        let text = expression.replace(templateMatcher, (substring, value, index) => {
            value = valueObj[value];
            return value;
        });
        return text
    },

    getFromDBField: function(obj) {
        // ingestelde taal uit store
        var storedLanguage = store.state.localeLanguage;
        var array = obj.items;

        // pak alleen de taal voor het streepje (nl-NL =>> nl)
        var convertedLanguage = storedLanguage.toString().split("-")[0];

        // pak de string in de juiste taal in de array
        if (array == undefined) {
            console.error("TranslationErrorInKey: GEEN Array!!");
            return "TranslationErrorInKey: GEEN Array!!";
        } else if (!(convertedLanguage in array)) {
            convertedLanguage = 'nl';
            if (!(convertedLanguage in array)) {
                console.error("TranslationErrorInKey: ");
                console.error(array);
                return "TranslationErrorInKey: " + convertedLanguage;
            } else {
                var result = array[convertedLanguage];
            }
        } else {
            var result = array[convertedLanguage];
        }

        // als de string in de ingestelde taal niet beschikbaar is, dan maar nl
        if(result == null) {
            result = array['nl'];
        }

        return result;
    }
}

export const Selector = {


    SelectLanguageStringFromLangJSON: function(page, id) {
        // instantieer lang.js als var
        var json = Lang;


        // language uit store
        var storedLanguage = store.state.localeLanguage;

        var result;
        // resultaat ophalen
        // als de string niet bestaat, geef '' terug
        if(json[page] === undefined) {
            console.error("language error on page "+page+" "+id);
            result = 'ERROR:TRANS|PAGE';
        }
        else if(json[page][id] === undefined) {
            console.error("language error on page ID "+page+" "+id);
            result = 'ERROR:TRANS|ID';
        }
        else if(json[page][id][storedLanguage] == undefined) {
            console.error("language error on page ID Language " + page + " " + id + " " + storedLanguage);
            result = 'ERROR:TRANS|LANG';
        } else {
            result = json[page][id][storedLanguage];
        }
        
        return result;
    },

    SelectLanguageStringFromLangJSONWithParams: function(page, id, objectParams) {
        var string = Selector.SelectLanguageStringFromLangJSON(page, id)
        var parsedString = Selector.StringTemplateParser(string, objectParams)
        var replacedString = String(parsedString).replace('&quot;', '"').replace('&quot;', '"')
        return replacedString;
    },

    StringTemplateParser: function(expression, valueObj) {
        const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
        let text = expression.replace(templateMatcher, (substring, value, index) => {
            value = valueObj[value];
            return value;
        });
        return text
    },

    SelectLanguageStringFromArray: function(array) {
        // ingestelde taal uit store
        var storedLanguage = store.state.localeLanguage;
        
        // pak alleen de taal voor het streepje (nl-NL =>> nl)
        var convertedLanguage = storedLanguage.toString().split("-")[0];

        // pak de string in de juiste taal in de array
        if (array == undefined) {
            console.error("TranslationErrorInKey: GEEN Array!!");
            return "TranslationErrorInKey: GEEN Array!!";
        } else if (!(convertedLanguage in array)) {
            convertedLanguage = 'nl';
            if (!(convertedLanguage in array)) {
                console.error("TranslationErrorInKey: ");
                console.error(array);
                return "TranslationErrorInKey: " + convertedLanguage;
            } else {
                var result = array[convertedLanguage];
            }
        } else {
            var result = array[convertedLanguage];
        }

        // als de string in de ingestelde taal niet beschikbaar is, dan maar nl
        if(result == null) {
            result = array['nl'];
        }

        return result;
    }
}
