<template>
    <div class="popUpContainer" v-if="state.isLoaded">
        <div id="popUpContainer-mask" class="popUpContainer-mask" v-on:click="closePopUp()" v-bind="resetToTop">
            <div class="popUpContainer-wrapper">
                <!-- Inhoud staat in de folder Checkout !-->
                <div class="checkoutWrap" v-if="whichPopUpToOpen == 'checkout'">
                    <CheckoutPopUp v-once v-on:click.stop=""></CheckoutPopUp>
                </div>
                <div v-else-if="whichPopUpToOpen == 'availability'">
                    <AvailabilityPopUp v-once v-on:click.stop=""></AvailabilityPopUp>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import CheckoutPopUp from './Checkout/CheckoutPopUp.vue';
import AvailabilityPopUp from './AvailabilityPopUp.vue';
import {store} from '../store/index.js';
export default {
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
        }
    },
    props: {
        whichPopUpToOpen: {
            type: String
        },
    },
    components: {
        CheckoutPopUp,
        AvailabilityPopUp
    },
    computed: {
        // schuift de popup met de checkout naar boven na een addCart
        resetToTop() {
            if(store.state.resetPopUpToTop) {

                // alleen als document.getElementById('popUpContainer-mask') ook bestaat
                if(document.getElementById('popUpContainer-mask')) {
                    document.getElementById('popUpContainer-mask').scrollTop = 0
                }
                store.dispatch('resetPopUpToTop', false);
            }
        }      
    },
    methods: {
        closePopUp() {
            const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);

            store.dispatch('closePopUp');

             this.$emit('close'); // Emit het 'close' event
        },
    }
}
</script>

<style scoped>
.popUpContainer-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    overflow-y: scroll;
}

.popUpContainer-wrapper {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-top: 45px;        
    padding:0px 20px 0px 20px;
}

.checkoutWrap {
    width:90%;
    max-width:1100px;
}
@media (max-width: 767px) {
    .checkoutWrap {
        width:100%;
    }
    .popUpContainer-wrapper {
        padding:0px 5px 0px 5px;
    }
}
</style>