<template>
  <transition name="fade">
    <div
        v-if="visible"
        class="sliding-page"
        @click="close"
        style="text-align: center; background-color: rgba(0,0,0,0.7); padding: 10px 10px;"
    >
      <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="alertActive" :time="alertTime" :okButtonActive="alertOkButtonActive" @confirmAnswer="setAnswerFromAlert" @closeAlert="closeAlert"></Alert>

      <!-- Voeg hier je inhoud toe -->
      <div class="wrapper" v-if="isLoaded && invitationInfo!=undefined">
          <img src="@/assets/icons/icons8-close-48.png" class="close-button" @click="close" />
          <h1>{{ translate.get('Invitations','Privileges')}}</h1>


          <div v-for="uitnodiging in invitationInfo.invitations">
            <div>{{ translate.get('Invitations','Uw code')}} {{ uitnodiging.code }}</div>

              <div v-for="pakket in uitnodiging.pakkettenInfo">
                <div class="privilege">{{ pakket.pakketName }}</div>

                <div v-if="pakket.pakketItem" class="pakketItem" v-for="pItem in pakket.pakketItem">
                  <div class="maxMetPrijs" v-if="pItem.maxTickets!=0 && pItem.price==0">{{ translate.getWithParams('Invitations','MaxAantalGratis',{'aantal':pItem.maxTickets,'prijs':getPrice(pItem.price)}) }}:</div>
                  <div class="maxMetPrijs" v-if="pItem.maxTickets!=0 && pItem.price!=0">{{ translate.getWithParams('Invitations','MaxAantalMetPrijs',{'aantal':pItem.maxTickets,'prijs':getPrice(pItem.price)}) }}*:</div>
                  <div class="maxMetPrijs" v-if="pItem.maxTickets==0 && pItem.price==0">{{ translate.getWithParams('Invitations','GeenMaxAantalGratis',{'aantal':pItem.maxTickets,'prijs':getPrice(pItem.price)}) }}:<br /></div>
                  <div class="maxMetPrijs" v-if="pItem.maxTickets==0 && pItem.price!=0">{{ translate.getWithParams('Invitations','GeenMaxAantalMetPrijs',{'aantal':pItem.maxTickets,'prijs':getPrice(pItem.price)}) }}*:<br /></div>

                  <div v-for="(option, index) in pItem.options" :key="index">

                    <div v-if="option.eventID>0 && option.passepartoutID==0">
                      {{ translate.getFromDBField(option.eventTitle) }} / {{ translate.getFromDBField(option.ticketTypeTitle) }}<br />
                    </div>
                    <div v-if="option.passepartoutID>0">
                      {{ translate.getFromDBField(option.passepartoutTitle) }} <br />
                    </div>

                    <div class="datum" v-if="option.evShowDatesOptions !== 3">{{ getDatum(option.eventID) }}</div>

                    <!-- Plaats 'OF' achter iedere regel behalve de laatste -->
                    <div class="ofTekst" v-if="index < pItem.options.length - 1"> of </div>
                  </div>



                </div>
                <div v-if="!zijnErActievePakketItems()" style="padding:10px; margin-top:20px; background-color: lightcoral; color:white">{{ translate.get('Invitations','GEENACTIEVEITEMS') }}</div>
              </div>
          </div>
          <br />
          <br />
          <ul class="footerMessage">
              <li style="cursor: pointer" @click.stop="afmelden" v-html="translate.get('Invitations','Afmelden')+'.'"></li><br />
              <li v-html="translate.get('Invitations','PrijsINCLBTWEnServicekosten')+'.'"></li><br />
              <li v-if="invitationInfo.alreadyUsed" style="color:red; line-height: 14px;" v-html="translate.get('Invitations','invitatieIsAlgebruikt')+'.'"></li>
          </ul>
          <div v-if="hasAfwijkendeTijden()" class="bestelVoorMelding">
            <span style="color:white; font-weight: bold">{{ translate.get('Invitations','BestelOpTijd') }}:</span>
            <div v-for="uitnodiging in invitationInfo.invitations">
              <div v-for="pakket in uitnodiging.pakkettenInfo">
                <div class="pakketItem" v-for="pItem in pakket.pakketItem">
                    <div v-if="pItem.validUntil != '0000-00-00 00:00:00'">
                      <div v-for="(option, index) in pItem.options" :key="index">
                        {{ translate.getFromDBField(option.eventTitle) }} / {{ translate.getFromDBField(option.ticketTypeTitle)}} ({{getPrice(pItem.price)}}) : {{ translate.get('Invitations','VervaltOp') }} {{ Functions.formatDateTime(pItem.validUntil,'short','short',true,false,'long' ) }}. <br />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <hr style="clear: both;" />
          <br />
          <button class="btn btnGreen" @click="close">{{ translate.get('Checkout','Sluiten')}}</button>
      </div>



    </div>
  </transition>
</template>


<script setup>
    import { ref } from 'vue';
    import { store } from '@/store/index.js';
    import { computed, defineExpose } from 'vue';
    import { translate } from "@/language/language.js";
    import { Functions } from '@/util/util.js';
    import Alert from "@/components/Alert.vue";

    // Reactive variables
    const visible = ref(false);
    const codeTXT = ref('');
    const pakketten = ref([]);
    const codes = ref([]);
    const isLoaded = computed(() => store.state.isLoaded);
    const invitationInfo = computed(() => store.state.events.invitationInfo);

    const alertTitle = ref('Afmelden uitnodiging');
    const alertText = ref('Weet u zeker dat u zich wilt afmelden voor deze uitnodiging?');
    const alertType = ref(4);
    const alertActive = ref(false);
    const alertTime = ref(0);
    const alertOkButtonActive = ref(1);
    const setAnswerFromAlert = (answer) => {
      if (answer) {

        alertActive.value = false;
        alertType.value = 0;
        alertText.value = translate.get('Invitations','Bezig met afmelden ...');
        alertActive.value = true;

        // Afmelden
        // ...
        // Wissen van de code
        console.log('Call initshop from home.vue on reset invitationCode');
        var payload = {
          cancelInvitation: true
        };
        store.dispatch('initShop',payload).then(response => {
          localStorage.removeItem('invitation');
          //alertActive.value = false;
          //visible.value = false;
          this.$router.push(localStorage.getItem('entrancePath'));
        }).catch(error => {
          alertActive.value = false;
          visible.value = false;
        });

      } else {
        // Tekst terug zetten
        alertActive.value = false;
        alertType.value = 4;
        alertText.value = 'Weet u zeker dat u zich wilt afmelden voor deze uitnodiging?';
        alertTitle.value = 'Afmelden uitnodiging';
      }

    };


    const getPrice = (price) => {
        // Format price naar euro met 2 decimalen via de actieve locale
        return Functions.formatMyCurrency(price);
    };

    const open = () => {
      visible.value = true;
    };

    const close = () => {
      alertActive.value = false;
      visible.value = false;
    };

    const closeAlert = (close) => {
      this.alertActive.value = close;
      this.alertTitle.value = ''
      this.alertText.value = ''
      this.alertType.value = ''
      this.alertTime.value = null
      this.alertOkButtonActive.value = 0;

    };







    const zijnErActievePakketItems = () => {
      for(let uitnodiging of store.state.events.invitationInfo.invitations) {
        for (let pakket of uitnodiging.pakkettenInfo) {
          if (pakket.pakketItem != undefined) {
            for(let pItem of pakket.pakketItem) {
              return true;
            }
          }
        }
      }
      return false;
    }

    const hasAfwijkendeTijden = () => {
      for(let uitnodiging of store.state.events.invitationInfo.invitations) {
        for(let pakket of uitnodiging.pakkettenInfo) {
          if (pakket.pakketItem == undefined) {
            continue;
          }
          for(let pItem of pakket.pakketItem) {
            if(pItem.validUntil != '0000-00-00 00:00:00') {
              return true;
            }
          }
        }
      }
      return false;
    }

    const getDatum = (eventID) => {

      // Opzoeken event in stat
      var state = store.state.events;
      for(let event of state.events.items) {
        if(event.evID === eventID) {
          if (event.evShowDatesOptions === 0) {
            return Functions.formatDateTime(event.evFrom, 'short', 'long', true, true);
          }
          if (event.evShowDatesOptions === 1) {
            return translate.get('General', "t/m") + " " + Functions.formatDateTime(event.evUntil, 'short', 'long', true, true);
          }
          if (event.evShowDatesOptions === 2) {
            if (event.evFrom.substring(0, 10) === event.evUntil.substring(0, 10)) {
              return Functions.formatDateTime(event.evFrom, 'short', 'long', true, true) + " - " + Functions.formatDateTime(event.evUntil, 'short', 'long', false, true);
            } else {
              return Functions.formatDateTime(event.evFrom, 'short', 'long', true, true) + " - " + Functions.formatDateTime(event.evUntil, 'short', 'long', true, true);
            }
          }
          if (event.evShowDatesOptions === 3 && event.type == 'passepartout') {
            // Niks
            return translate.get('General', "meerdere data/tijden");
          }
          if (event.evShowDatesOptions === 3) {
            // Niks
            return "";
          }
          if (event.evShowDatesOptions == 99) {
            return event.evPeriodLabel.toLowerCase();
          }

        }
      }
    }

    const afmelden = () => {

      // Tekst met taal zetten
      alertTitle.value = translate.get('Invitations','Afmelden uitnodiging');
      alertText.value = translate.get('Invitations','Weet u zeker dat u zich wilt afmelden voor deze uitnodiging?');
      alertType.value = 4;
      alertActive.value = true;

    }


    // Expose functions so they can be accessed from the parent component
    defineExpose({
      open,
      close,
      afmelden,
    });

    // Return reactive variables
</script>

<style scoped>
.wrapper {
  position: relative;
  margin: auto;
  margin-top: 20px;
  padding: 30px 20px 30px 20px;
  max-width: 800px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: #bfbfbf 0px 0px 2px;
  border-radius: 8px;
}

.sliding-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 500;
  /* Toevoegen van verticale scrolling indien nodig */
  overflow-y: auto;  /* Scroll alleen verticaal */
  overflow-x: hidden; /* Zorg ervoor dat er geen horizontale scroll is */
}

/* Transitieklassen voor het schuiven */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.privilege{
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
}
.maxMetPrijs {
  font-variant: all-small-caps;
  font-weight: bold;
  color: #0880b8;
  margin-top: 10px;
}
.pakketItem {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ofTekst {
  font-variant: all-small-caps;
  font-weight: lighter;

}
.datum {
  font-size: 12px;
  font-weight: lighter;
  color: #666;
}
.close-button {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
}
.footerMessage {
  margin: 0 0 0 20px;
  text-align: left;
  list-style-type: square;
  font-size: small;
  line-height: 5px;
}

.footerMessage > li {
  padding: 0;
  margin: 0;
  line-height: 12px;
}
.footerMessage > li > u {
  cursor: pointer;
}
.bestelVoorMelding {
  background-color: #2c3e50;
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  color: white;
}

</style>