<template>
  <InvitationInfo ref="invitationInfo" />
  <div class="ticketOrderList">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="setAnswerFromAlert" @closeAlert="closeAlert"></Alert>
        <!-- datum en tijd -->
        <div class="buttonWrapper">
            <button v-if="!state.isDirectCart" class="btn btnWhite btnVerderWinkelen" v-on:click="closePopUp()">{{translateString('Checkout', 'VerderWinkelen')}}</button>  
            <!--<button class="btn btnBestellingAfronden" v-bind:class="{rightAlignBtnDirectCart: state.isDirectCart, btnGreen: (hasCart()), btnDisabled: (!hasCart())  }" v-on:click="nextView">{{translateString('Checkout', 'Bestellen')}} <font-awesome-icon icon="caret-right" /></button>-->
          <button class="btn btnBestellingAfronden" v-bind:class="btnNextClass" v-on:click="nextView">{{translateString('Checkout', 'Bestellen')}} <font-awesome-icon icon="caret-right" /></button>
        </div>
        <div class="lijn"></div>


        <div v-if="state.selectedEvent.evHasTimeSlots">
            <div class="labelKiesDatumTijd">{{translateString('Checkout', "KiesEenDatumEnTijd")}}</div>
            <div class="calendarWrapper">
                <!-- TODO loading .... text en ticketview-spinnen -->
                <h3 v-if="state.timeslots==null" >Ophalen beschikbaarheid ....</h3>
                <img style="text-align: center;" v-if="state.timeslots==null" class="calendarLoading" src="@/assets/Loader.gif" />
                <Calendar :selectslot=1 :reload="reloadCalender"></Calendar>
            </div>
        </div>
        

        <div  v-if="!checkSeats()" class="labelKiesTickets">{{translateString('Checkout', "KiesJeTickets")}}*</div>
        <div  v-if="checkSeats()" class="labelKiesTickets">{{translateString('Checkout', "KiesJeStoelen")}}</div>

        <div class="ticketTypeContainer"> 
            <div v-for="(ticketType, index) in state.selectedEvent.ticketTypes.items" v-bind:key="ticketType.ticketTypeKey">
                <div v-if="checkIfTicketTypeIsVisible(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == 0">
                </div>
                <div v-else>
                    <TicketSelector v-bind:seatingPlanID="state.selectedEvent.evSeatingPlanID"
                                     v-bind:ticketType="ticketType" 
                                     v-bind:besteld="checkIfThisTypeTicketIsOrdered(ticketType.uniqueKey)"
                                     :key="forceUpdate"
                                     @selected="getSelectedFromTicketSelector" 
                                     ></TicketSelector>
                    <div>
                      <div class="infoPakketItem" v-if="ticketType.pakketItemID>0">
                        <img style="max-height: 16px; margin-top: 1px;" src="@/assets/icons/icons8-info.png" />
                        <img v-show="getIcon(ticketType,false)" :src="getIcon(ticketType,true)" alt="Ticket Icon" class="pakketIcon" :class="getIconClass(ticketType)" />
                        <small>{{translateString("General",'Speciaal voor u:')}} "{{ ticketType.pakketName }}"</small>
                      </div>
                    </div>
                    <div v-if="(index+1) < state.selectedEvent.ticketTypes.count">
                        <div class="lijn"></div>
                    </div>
                </div>           
            </div>
            <br />
            <div class="labelAllePrijzenInclServicekosten">* {{translateString("General",'allePrijzenZijnInclServicekosten')}}</div>
            <div v-if="hasPersonalisedTickets()>0" class="labelErZijnGepersonaliseerdeVelden">** {{translateString("General",'erZijnGepersonaliseerdeVelden')}}</div>
            <div style="font-size: 14px; margin-top: 10px;" v-if="hasPakketItems()">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/icons/icons8-info.png" style="max-height: 14px; margin-top: 1px; margin-right: 5px;" />
                {{translateString("General","SpeciaalVoorU")}}
              </div>
              {{translateString("General","SpeciaalVoorU1")}}<br />
              {{translateString("General","SpeciaalVoorUMeerUitleg")}}
              <a href="#" @click.prevent="showInvitationInfo">{{translateString("General","KlikHier")}}</a>
            </div>


        </div>
    </div>
</template>

<script>
import {store} from '@/store/index.js';
import {Functions} from '@/util/util.js';
import TicketSelector from './TicketSelector.vue';
import TicketRemover from './TicketRemover.vue';
import {Selector} from '@/language/language.js';
import Calendar from '@/components/Calendar.vue';
import Alert from '@/components/Alert.vue';
import InvitationInfo from '@/components/InvitationInfo.vue';
import {ref} from "vue";


import $ from 'jquery'
export default {
    name: 'TicketOrderList',
    setup() {
      const invitationInfo = ref(null);
      const showInvitationInfo = () => {
        invitationInfo.value.open();
      };
      return {
        invitationInfo,
        showInvitationInfo
      }
    },
    data() {
        return {
            state: store.state,
            alertTitle: '',
            alertText: '',
            alertType: '',
            active: false,
            alertTime: null,
            okButtonActive: 0,
            answer: null,
            dataFromTicketSelector: null,
            forceUpdate: 0,
            reloadCalender: 0  ,
            hasNextBtn: false

        }
    },
    components: {
        TicketSelector,
        TicketRemover,
        Calendar,
        Alert,
        InvitationInfo,
    },
    computed: {
        btnNextClass() {
          var cl = "";
          if (this.hasCart()) {
            cl =  "btnGreen";
          } else {
            cl =  "btnDisabled";
          }
          if (this.state.isDirectCart) {
            cl = " rightAlignBtnDirectCart " + cl;
          }
          return cl;
        },
        cartFromStore() {
            var updatedCart = null;
            if(store.state.cart.length > 0 ) {
                updatedCart = store.state.cart
            }        
            return updatedCart
        },
    },
    methods: {
      hasPakketItems() {
        var ret = false;
        for (var i = 0; i < store.state.selectedEvent.ticketTypes.items.length; i++) {
          if (store.state.selectedEvent.ticketTypes.items[i].pakketItemID > 0) {
            ret = true;
            break;
          }
        }
        return ret;
      },
      getIconClass(ticketType) {
        if (ticketType.ttPrice==0) {
          return 'pakketIconGift';
        } else {
          return 'pakketIconDiscount'
        }
      },
      getIcon(ticketType, returnAsIcon) {
        if (returnAsIcon) {
          if (ticketType.ttPrice == 0) {
            return new URL('@/assets/icons/icons8-gift.png', import.meta.url).href;
          } else {
            return new URL('@/assets/icons/icons8-discount.png', import.meta.url).href;
          }        } else {
          if (ticketType.pakketItemID == 0) {
            return false;
          } else {
            if (ticketType.ttPrice==0 && ticketType.pakketOriginalPrice!=0) {
              return true;
            } else if (ticketType.ttPrice!=ticketType.pakketOriginalPrice) {
              return true;
            } else {
              return false;
            }
          }
        }
      },
      hasPersonalisedTickets() {
        var ret = false;
        // doorloop alle tickettypes en kijk of er een bij zit voor gepersonaliseerde ticket
        for (var i = 0; i < store.state.selectedEvent.ticketTypes.items.length; i++) {
          if (store.state.selectedEvent.ticketTypes.items[i].ttHasCheckoutFields > 0) {
            ret = true;
            break;
          }
        }
        return ret;
      },

      //seatingplan
        checkSeats() {
            if (store.state.selectedEvent.evSeatingPlanID != 0) {
                return true
            }
            else{
                console.log(store.state.selectedEvent.evSeatingPlanID);
                return false
            }

        },
        //seatingplan
        hasCart() {
                if (store.state.cart.bestelItems!=undefined && store.state.cart.bestelItems.length>0) {
                    return true;
                } else {
                    return false;
                }
        },
        newBestelItem(dataFromTicketSelector) {
            var ticketTypeID = dataFromTicketSelector.id;
            var eventID = this.state.selectedEvent.ID;
            var quantity = dataFromTicketSelector.quantity;
            var price = dataFromTicketSelector.ticketPrice;
            var pakketItemID = dataFromTicketSelector.pakketItemID;
            var accountId = this.getAccountIdTicketType();
            var timeSlot = this.getTimeSlotIfNeeded();

            // set alert        
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'BeschikbaarheidControleren');

            store.dispatch('addCart', {accountId: accountId, eventID: eventID, ticketTypeID: ticketTypeID, timeSlot: timeSlot, pakketItemID: pakketItemID, price: price, quantity: quantity, seat: {}}).then(response => {
                console.log(response);

                if(response.status === "OK"){
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = response.message;
                    this.alertType = 1;
                    this.alertTime = 1000;          
                } else {

                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2; // Error type
                    //this.alertTime = null;
                    this.okButtonActive = 1;

                    this.forceUpdate++; // zorgt dat hij refresht.
                    this.reloadCalender++;
 
                }
                  
            })        
        },
        // remove item
        removeBestelItemByIndex(i) {
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'TickettypeVerwijderen');

            var itemToRemove = store.state.cart.bestelItems[i]

            // accountId, key
            var accountId = itemToRemove.accountId;
            var key = itemToRemove.key

            store.dispatch('delCart', {accountId: accountId, key: key}).then(response => {
                  if (response.status === 'OK') {
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = this.translateString('Alert', 'TickettypeIsVerwijderdUitDeWinkelwagen');
                    this.alertType = 1;
                    this.alertTime = 1000;
                  } else {
                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2;
                    this.alertTime = 2000;
                  }
            }) 
        },
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        getAccountIdTicketType() {
            var accountID = this.state.selectedEvent.accAccountID;
            return String(accountID)
        },
        getTimeSlotIfNeeded() {
            if(this.state.selectedEvent.evHasTimeSlots == 1) {
                return store.state.currentSlot.slotFrom
            }
            else {
                return ''
            }
        },
        addBestelItem(dataFromTicketSelector) {
            /*
            Beetje slordige functie, maar het iedee is:
            Als er al een item besteld is van een ander account, moet de gebruiker een alert krijgen dat er al een item in de winkelwagen zit van een andere organisatie.
            Hij kan danb het toeveogen afbreken of doorgaan (en dan wordt de oude bestelling verwijderd).
            Maar het werkt.
            */

            // checken of bestelItems null is (dan kan hij altijd toegeveogd worden)
            if(store.state.cart.bestelItems.length == 0) {
                //store.dispatch('initCustomerFormPaymentForm');
                this.newBestelItem(dataFromTicketSelector);               
            }
            // als bestelitems niet null is
            else if(store.state.cart.bestelItems.length != 0) {
                var tryAddingTicketAgain = 1;

                // door de bestelitems heen fietsen
                for(var i = 0; i < store.state.cart.bestelItems.length; i++) {
                    var timeSlot = this.getTimeSlotIfNeeded()
                    // kijken of in de cart al een item zit met dezelfde eventID/ticketTypeID/timeSlot, dan kun je dat item updaten
                    if(store.state.cart.bestelItems[i].ID == this.state.selectedEvent.ID && store.state.cart.bestelItems[i].ticketTypeID == dataFromTicketSelector.id && store.state.cart.bestelItems[i].timeSlot == timeSlot) {
                        this.newBestelItem(dataFromTicketSelector);
                        tryAddingTicketAgain = 0;   
                        return;
                    }
                }

                // check of ticket van een andere organisator is (vergeleken met ticket die al in de winkelwagen zitten)
                if(tryAddingTicketAgain == 1) {
                    if(this.state.selectedEvent.accAccountID == store.state.cart.bestelItems[0].accountId) {
                        this.newBestelItem(dataFromTicketSelector);
                        tryAddingTicketAgain = 0;
                    }
                    else {
                        this.active = true;
                        this.alertTitle = this.translateString('Alert', 'ErZittenTicketsVanEenAndereOrganisatieInUwWinkelwagen');
                        this.alertText = this.translateString('Alert', 'WiltUTochDoorgaan');
                        this.alertType = 3;

                        // de data van de selector even als losse variabele parkeren
                        this.dataFromTicketSelector = dataFromTicketSelector;

                        // wachten op het antwoord van de gebruiker
                        this.waitForConfirmAnswer();      

                        tryAddingTicketAgain = 0;
                    }
                }

                // als het ticket nog steeds toegevoegd mag worden (tryAddingTicketAgain == 1), dan doe dat hier
                 else if(tryAddingTicketAgain == 1) { 
                    this.newBestelItem(dataFromTicketSelector);
                }
            }
        },
        waitForConfirmAnswer(){
            if (this.answer == null){
                setTimeout(this.waitForConfirmAnswer,100);
            } else {
                // check welk antwoord de gebruiker heeft gegeven
                if(this.answer == true) {
                    // de cart leeggooien, zodat de nieuwe tickets toegevoegd kunnen worden
            
                    store.dispatch('emptyCart').then(response => {
                        // de nieuwe kaarten toevoegen
                        this.newBestelItem(this.dataFromTicketSelector);
                    })                

                    // nu kunnen het antwoord en de tijdelijke opgeslagen dataFromTicketSelector weer op null
                    this.answer = null;
                    //this.dataFromTicketSelector = null;
                    return;
                } else {
                    this.dataFromTicketSelector = null;
                    this.active = false;
                    this.answer = null;
                    return;
                }
            }
        },
        removeBestelItem(dataFromTicketSelector) {
            if(store.state.cart.bestelItems.length != 0) {
                var timeSlot = this.getTimeSlotIfNeeded()  
                for(var i = 0; i < store.state.cart.bestelItems.length; i++) {
                    
                    var keyToFind = dataFromTicketSelector.key +'|'+ timeSlot + '|0|'
                    console.log(keyToFind + " = "+ store.state.cart.bestelItems[i].key);
                    if(store.state.cart.bestelItems[i].key == keyToFind) {
                        this.removeBestelItemByIndex(i);
                    }
                }
            }
        },
        getSelectedFromTicketSelector(dataFromTicketSelector, callback) { 
            if(dataFromTicketSelector.quantity == "0") {               
                this.removeBestelItem(dataFromTicketSelector);
            }
            else {
                if(this.state.selectedEvent.evHasTimeSlots == 1) {
                    if(store.state.currentSlot == '') {
                        this.alertTitle = this.translateString('Alert', 'Oeps');
                        this.alertText = this.translateString('Alert', 'JeMoetEerstEenTijdslotKiezen');
                        this.alertType = 2;
                        this.okButtonActive = 1;
                        this.active = true;

                        callback("reset")
                    }
                    else {
                        this.addBestelItem(dataFromTicketSelector);
                    }
                } else {
                    this.addBestelItem(dataFromTicketSelector);
                }        
            }          
        },
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
        checkIfThisTypeTicketIsOrdered(ticketTypeKey) {
           
            var slot = "";
            if (store.state.currentSlot!=undefined && store.state.currentSlot.slotFrom!="") {
                slot = store.state.currentSlot.slotFrom;
            }


            var ticketIsOrdered = false;
            if(store.state.cart.bestelItems.length > 0) {             
                for(var i = 0; i < store.state.cart.bestelItems.length; i++) {
                    if(store.state.cart.bestelItems[i].ticketTypeKey == ticketTypeKey) {
                        if(this.state.selectedEvent.evHasTimeSlots == 1){
                            if(store.state.cart.bestelItems[i].timeSlot == slot) {
                                ticketIsOrdered = true;
                                return store.state.cart.bestelItems[i];
                            }
                        }
                        else {
                            ticketIsOrdered = true;
                            return store.state.cart.bestelItems[i];
                        }                         
                    }
                }
            }

            if(ticketIsOrdered == false) {
                return null //{accountId: '', eventID: '', eventName: '', key: '', price: 0, quantity: '1', ticketTypeID: ticketTypeId, ticketTypeName: '', timeSlot: ''};
            }
        },

        checkIfTicketTypeIsVisible(ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption) {
            return Functions.checkIfTicketTypeIsVisible(ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption);
        },
        
        setAnswerFromAlert(answer) {
            this.answer = answer
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        },
        closePopUp() {

          // Indien close via de client pagina, dan de iFrame sluiten
          if (store.state.isClientPage) {
            const message = {
              type: 'close'
            };
            // TODO: check if this works
            if (document.referrer !== null && document.referrer !== undefined && document.referrer !== '') {
              window.parent.postMessage(message, document.referrer);
            }
            return;
          }


          const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);
            
            store.dispatch('changeCurrentSlot', {slot:'',available:0});
            store.dispatch('closePopUp');
        },
        nextView() {
            if(store.state.cart.bestelItems.length != 0) {
                var currentStep = store.state.step;
                if(currentStep == 'bestellen') {
                    currentStep = 'klantinformatie';
                    store.dispatch('changeStep', currentStep);
                }
                else if(currentStep == 'klantinformatie'){
                    currentStep = 'betalen';
                    store.dispatch('changeStep', currentStep);
                }
            }
            else {
             //   this.alertTitle = this.translateString('Alert', 'Oeps');
             //   this.alertText = this.translateString('Alert', 'ErZittenGeenTicketsInDeWinkelwagen');
             //   this.alertType = 2;
             //   this.okButtonActive = 1;
             //   this.active = true;
            }
        },
    },
    mounted() {
        store.dispatch('saveTimeSlots',null);
        var shopId = Functions.getShopID();
        if(localStorage.getItem("shopId") === null) {
            localStorage.setItem("shopId", shopId);
        } else if(localStorage.getItem("shopId") != shopId) {
            localStorage.setItem("shopId", shopId);
        }
    },
}
</script>

<style scoped>


.calendarLoading {    
   margin-top:30px;
   width: 50px;   
   margin-left:200px;
}

.ticketOrderList {
    position: relative;
    padding: 20px 20px 20px 20px;
}

.btnVerderWinkelen {
    left: 0px;
}

.rightAlignBtnDirectCart {
    margin-left: 450px;
}

.labelKiesTickets, .labelKiesDatumTijd {
    font-size: 22px;
    font-weight: bold;
}

.ticketTypeContainer {
    margin-top: 20px;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 10px 0px 10px 0px;
}
.subtotaallijn {
  border-top: 1px solid gray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;  
}
.totaallijn {
  border-top: 1px solid black;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;  
}

.totaalContainer, .subTotaalContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.totaalLabelWrapper, .subTotaalLabelWrapper {
    display: flex;
    flex-direction: row;
    width: 88%;
}
.labelTotaal, .labelSubTotaal {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
}
.labelKosten, .labelSubKosten {
    color: grey;
    align-self: flex-end;
}

.totaalPriceWrapper, .subTotaalPriceWrapper {
    width: 12%; 
}
.totaalPrice, .subTotaalPrice {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    float: right;
    align-self: flex-end;
}

/* Calendar & timeslots */

.calendarWrapper  {
    padding-top:30px;
    min-height:350px;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
}

.pakketIcon {
  max-height: 16px;
}
.pakketIconGift {
  margin-top: 0px;
  max-height: 17px;
}
.pakketIconDiscount {
  margin-top: 1px;
}
.infoPakketItem {
  display:flex;
  align-items: center;
  margin-top:-4px;
  font-style: italic;
}
.infoPakketItem small {
  margin-top: 1px;
  margin-left: 3px;
}


@media(max-width: 1120px) {
    .btnBestellingAfronden {
        margin-left: auto;
    }    
}
@media(max-width: 550px) {
    .btnVerderWinkelen {
        margin-right: 10px;
    }   
}
</style>