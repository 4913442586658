<template>
  <!-- Voettekst van de website die alleen wordt getoond als de state is geladen -->
  <footer class="site-footer" :style="{ backgroundColor }" v-if="state.isLoaded">
    <div class="container" :style="{ color: textColor }">
      <div class="teksten">
        <!-- Dynamische vertalingen voor de tekst in de voettekst -->
        {{ translate.getWithParams('Footer', 'DeETicketsWordenVerwerktDoorTicketView', { accountName: state.status.accountName }) }}.
        {{ translate.get('Footer', 'TicketViewIsNietAansprakelijk') }}.
        {{ translate.getWithParams('Footer', 'VerdereContactenLopenVia', { accountName: state.status.accountName }) }}.
      </div>
      <div class="lijn" :style="{ borderTopColor: textColor }"></div>
      <div class="bottom-links">
        <div class="algemene-voorwaarden-text">
          <!-- Link naar de algemene voorwaarden -->
          <a :style="{ color: textColor }" href="https://www.ticketview.nl/mvc/assets/pdf/algemene_voorwaarden.pdf">
            {{ translate.get('Footer', 'AlgemeneVoorwaarden') }} &amp; {{ translate.get('Footer', 'Disclaimer') }}
          </a>
        </div>
        <div class="linkDivider">|</div>
        <div class="copyright-text">
          &copy;
          <!-- Copyright link naar TicketView -->
          <a :style="{ color: textColor }" href="https://www.ticketview.nl/">TicketView</a> {{ year }}
        </div>
        <!-- TicketView logo in de voettekst -->
        <img class="ticketViewLogo" src="../assets/TicketViewLogo.png" />
      </div>
      <div style="clear:both;"></div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue';
import { translate } from '../language/language.js';
import { store } from '../store/index.js';

// Reactieve state van de store
const state = store.state;

// Data eigenschappen
const textColor = computed(() => state.status.layoutTextColor || 'white');
const backgroundColor = computed(() => state.status.layoutBackgroundColor || '#005b8c');

// Computed property voor het huidige jaar
const year = computed(() => new Date().getFullYear());
</script>

<style scoped>
.site-footer {
  background-color: #005b8c;
  width: 100%;
}

.container {
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-family: 'Didact Gothic', sans-serif;
  padding: 20px 20px;
  max-width: 1200px;
  margin: auto;
  font-size: 12px;
}

.lijn {
  border-top: 1px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.site-footer a {
  color: white;
  text-decoration: none;
}

.algemene-voorwaarden-text,
.disclaimer-text,
.copyright-text,
.linkDivider {
  padding-right: 10px;
  float: left;
}

.ticketViewLogo {
  height: 15px;
  float: right;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .ticketViewLogo {
    margin-top: 10px;
  }
}
</style>
