<template>
    <div class="availability" v-if="state.isLoaded">
        <h3 v-if="state.timeslots==null" >Ophalen beschikbaarheid ....</h3>
        <img v-if="state.timeslots==null" class="calendarLoading" src="@/assets/Loader.gif" />
        <Calendar v-once :selectslot="0"></Calendar>
    </div>
</template>

<script>
import Calendar from './Calendar.vue';
import {store} from '@/store/index.js';
export default {
    name: 'Availability',
    setup() {
        return {
            state: store.state,
        }
    },
    components: {
        Calendar
    },
    mounted() {
        store.dispatch('saveTimeSlots',null);
    },
}
</script>

<style scoped>
.availability {
    width: 500px;
    left: 50%;
    margin-left: -250px;
    padding:60px 60px 60px 60px;
    height: auto;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}
</style>