import { createApp } from 'vue';
import App from './App.vue'
import {store} from './store/index.js'
import router from './router'
import axios from 'axios';
import VueGtag from 'vue-gtag-next';

/* fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faAlignLeft,faUserSecret,faHome,faTimesCircle,faCaretUp,faCaretDown,faCaretLeft,faCaretRight } from '@fortawesome/free-solid-svg-icons'
import {Functions} from "@/util/util.js";

library.add(faSpinner, faAlignLeft,faUserSecret,faHome,faTimesCircle,faCaretUp,faCaretDown,faCaretLeft,faCaretRight)


const app = createApp(App)


/************************************************************************************************
/* error opvang
/************************************************************************************************/
// 1. Vue specifieke error handler
app.config.errorHandler = function (err, vm, info) {
    console.error(`Vue Error: ${err.toString()}\nInfo: ${info}`);
    logError({
        type: 'vue-error',
        message: err.toString(),
        source: vm ? vm.$options.name : 'unknown',
        lineno: null,
        colno: null,
        stack: err.stack || null,
    });
};

// 2. Globale JavaScript error handler
window.onerror = function (message, source, lineno, colno, error) {
    console.error(`Global Error: ${message} at ${source}:${lineno}:${colno}`);

    logError({
        type: 'global-error',
        message,
        source,
        lineno,
        colno,
        stack: error ? error.stack : null,
    });

    return true; // Voorkomt dat de fout in de console verschijnt
};

// 3. Unhandled Promise rejection handler
window.addEventListener('unhandledrejection', event => {
    console.error(`Unhandled promise rejection: ${event.reason}`);

    logError({
        type: 'unhandled-rejection',
        message: event.reason.toString(),
        source: 'Promise',
        lineno: null,
        colno: null,
        stack: event.reason?.stack || null,
    });
});

// 4. errors doorgeven aan de server
// Centrale error logging functie
function logError({ type, message, source, lineno, colno, stack }) {
    // Maak een unieke error key gebaseerd op de error details
    const errorKey = `${type}-${message}-${lineno}-${colno}-${stack}`;

    // Haal de lijst met al gemelde errors op uit localStorage
    const reportedErrors = JSON.parse(localStorage.getItem('errors') || '[]');

    // Controleer of de error al gemeld is
    if (reportedErrors.includes(errorKey)) {
        console.error("Error is al eerder gemeld. Geen actie nodig:" + errorKey);
        return;
    }

    // Voeg de nieuwe error toe aan de lijst en sla deze op in localStorage
    reportedErrors.push(errorKey);
    localStorage.setItem('errors', JSON.stringify(reportedErrors));

    // Veilig verzamelen van systeem- en browserinformatie
    const browserInfo = {};

    if (typeof navigator !== 'undefined') {
        if (navigator.userAgent) browserInfo.userAgent = navigator.userAgent;
        if (navigator.platform) browserInfo.platform = navigator.platform;
    }

    if (typeof window !== 'undefined') {
        if (window.screen && window.screen.width) browserInfo.screenWidth = window.screen.width;
        if (window.screen && window.screen.height) browserInfo.screenHeight = window.screen.height;
        if (window.innerWidth) browserInfo.viewportWidth = window.innerWidth;
        if (window.innerHeight) browserInfo.viewportHeight = window.innerHeight;
    }

    // Maak payload object
    const payload = {
        type,
        message,
        source,
        lineno,
        colno,
        stack,
        browserInfo
    }

    store.dispatch('setErrorLog', payload).then(response => {
        // Toon een alert en reset de applicatie
        alert(`\nOeps, er is een fout opgetreden.\n\nDe applicatie zal nu herstarten.\n\nError ID: ${errorKey}`);
        const errorsToKeep = localStorage.getItem('errors');
        localStorage.clear();
        localStorage.setItem('errors', errorsToKeep); // Alleen de errors behouden
        window.location.href = '/'; // Navigeer altijd naar de root van de site

    }).catch(error => {
        // Toon een alert en reset de applicatie
        alert(`\nOeps, er is een fout opgetreden.\n\nDe applicatie zal nu herstarten.\n\nError ID: ${errorKey}`);
        const errorsToKeep = localStorage.getItem('errors');
        localStorage.clear();
        localStorage.setItem('errors', errorsToKeep); // Alleen de errors behouden
        window.location.href = '/'; // Navigeer altijd naar de root van de site

    });
    /*
    store.dispatch('setErrorLog',payload).then(response => {
        // Toon een alert en reset de applicatie
        alert(`\nOeps, er is een fout opgetreden.\n\nDe applicatie zal nu herstarten.\n\nError ID: ${errorKey}`);
        const errorsToKeep = localStorage.getItem('errors');
        localStorage.clear();
        localStorage.setItem('errors', errorsToKeep); // Alleen de errors behouden
        window.location.href = '/'; // Navigeer altijd naar de root van de site

    }).catch(error => {
        // Toon een alert en reset de applicatie
        alert(`\nOeps, er is een fout opgetreden.\n\nDe applicatie zal nu herstarten.\n\nError ID: ${errorKey}`);
        const errorsToKeep = localStorage.getItem('errors');
        localStorage.clear();
        localStorage.setItem('errors', errorsToKeep); // Alleen de errors behouden
        window.location.href = '/'; // Navigeer altijd naar de root van de site
    });
     */

}



/************************************************************************************************
 /* APP
 /************************************************************************************************/
app.component('font-awesome-icon', FontAwesomeIcon)

// Voeg router en store toe eerst toe
app.use(router);
app.use(store);

// Initialiseer Vue GTM met dynamische enabled waarde voor Google Analytics
if (localStorage.getItem("TicketViewCookie") === 'accepted' && localStorage.getItem('shopAnalytics') !== '') {


    var analyticsID = localStorage.getItem('shopAnalytics');
    if (analyticsID !== '' && analyticsID !== null && analyticsID !== undefined) {
        app.use(VueGtag, {
            property: {
                id: analyticsID  // Vervang door je GA4-ID
            },
            config: {
                send_page_view: true  // Automatisch paginaweergaven volgen
            }
        });
    }



}

if (localStorage.getItem("TicketViewCookie") === 'accepted' && localStorage.getItem('shopFacebookPixel') !== '') {

    var pixelID = localStorage.getItem('shopFacebookPixel');

    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', pixelID); // Vervang met je Pixel ID
    fbq('track', 'PageView');

}

/* coomentaar */

// **Mount de app als laatste stap**
app.mount('#app');
