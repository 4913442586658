<template>
  <div class="confetti-container"></div>
</template>

<script>
import { onMounted } from 'vue';
import confetti from 'canvas-confetti';

export default {
  name: 'Confetti',
  setup() {
    onMounted(() => {
      confetti({
        particleCount: 100,
        spread: 160,
        origin: {y: 0.6},
      });
    });
  },
};
</script>

<style>
.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}
</style>