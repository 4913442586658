<template>
    <div class="shoppingCart" v-if="state.isLoaded">
        <div class="mask" v-if="state.step == 'voorwaarden'"></div>
        <div class="shoppingCart-header">               
            <div class="shoppingCartTitle">{{translateString('Shoppingcart')}}</div>
        </div>
        <div class="shoppingCart-body">
            <ShoppingList></ShoppingList>
        </div>
    </div>
</template>

<script>
import {store} from '@/store/index.js';
import {Selector} from '@/language/language.js';
import ShoppingList from '../components/ShoppingList.vue';
export default {
    name: 'ShoppingCart',
    components: {
        ShoppingList
    },
    setup() {
        return {
            state: store.state,
        }
    },
    methods: {
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated)
            return translatedString;
        },
    }
}
</script>

<style scoped>
.shoppingCart {
    position: relative;
    text-align: left;
}

.shoppingCart-header {
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    text-align: center;
}
.shoppingCartTitle {
    overflow: hidden;
    font-weight: bold;
    font-size: 28px;   
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}

.shoppingCart-body {
    min-height: 20px;
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
} 
</style>