<template>
    <div class="sharer-class">
        <WhatsappShare :title="title" :url="url" v-once />
        <TwitterShare :title="title" :url="url" v-once />
        <FacebookShare :url="url" v-once />
        <LinkedinShare :url="url" v-once />
        <PinterestShare :url="url" :image="image" v-once />
        <EmailShare :title="title" :url="url" v-once />
    </div>
</template>

<script>
import WhatsappShare from './SocialButtons/WhatsappShare.vue'
import TwitterShare from './SocialButtons/TwitterShare.vue'
import FacebookShare from './SocialButtons/FacebookShare.vue'
import LinkedinShare from './SocialButtons/LinkedinShare.vue'
import PinterestShare from './SocialButtons/PinterestShare.vue'
import EmailShare from './SocialButtons/EmailShare.vue'
import {Selector} from '../language/language.js'

export default {
    components: {
        WhatsappShare,
        TwitterShare,
        FacebookShare,
        LinkedinShare,
        PinterestShare,
        EmailShare
    },
    props: {
        eventTitle: {
            type: String
        },
        accountName: {
            type: String
        },
        image: { 
        }
    },
    computed: {
        title() {
            var stringCheckNu = Selector.SelectLanguageStringFromLangJSONWithParams('Social', 'CheckNu', {eventTitle: this.eventTitle, accountName: this.accountName})
            return stringCheckNu;
        },
        url() {
            return String(location.href)
        }
    },
    mounted() {
      const script = document.createElement('script');
      script.src = '/static/sharer.js';
      script.async = true;
      script.onload = () => {
        console.log('Script loaded successfully');
        // Roep hier een functie uit `sharer.js` aan als deze beschikbaar is
        if (window.sharer) {
          window.sharer.init();  // Voorbeeldfunctie als `sharer` globaal is gedefinieerd
        }
      };
      script.onerror = () => {
        console.error("Failed to load the script");
      };
      document.head.appendChild(script);    }
}
</script>

<style scoped>
.sharer-class {
    display: flex;
    flex-wrap: wrap;
}
</style>