<template>
    <div class="eventCard">
        <img class="eventImage" :src="image" />
        <div class="eventInfoCntr">
            <div class="eventDate">
                <div v-if="showDatesOptions == 0">
                    <div>{{ getDateAndTimeFormatted(dateFrom, showDatesOptions, true, true) }} <!-- {{ getDateAndTimeFormatted(dateUntil) }}  --></div>
                </div>
                <div v-if="showDatesOptions == 1">
                    <div>{{translateString('General', "t/m")}} {{getDateAndTimeFormatted(dateUntil, showDatesOptions, true, true)}}</div>
                </div>
                <div v-if="showDatesOptions == 2">
                    <div v-if="getDateAndTimeFormatted(dateFrom, showDatesOptions, true, false) == getDateAndTimeFormatted(dateUntil, showDatesOptions, true, false)">
                        <div>{{ getDateAndTimeFormatted(dateFrom, showDatesOptions, true, true)}} - {{getDateAndTimeFormatted(dateUntil, showDatesOptions, false, true)}}</div>
                    </div>
                    <div v-else>
                        <div>{{ getDateAndTimeFormatted(dateFrom, showDatesOptions, true, false)}} - {{getDateAndTimeFormatted(dateUntil, showDatesOptions, true, false)}}</div>
                    </div>
                </div>
                <div v-if="showDatesOptions == 3 && cardtype == 'passepartout'">
                  <div>{{translateString('General', "meerdere data/tijden")}}</div>
                </div>
                <div v-if="showDatesOptions == 3">
                </div>
                <div v-if="showDatesOptions == 99">
                    <div>{{ periodLabel }}</div>
                </div>
            </div> 

            <div v-if="soldOut">
                <div v-if="allowRegister">
                    <div class="eventTitle" v-html="title"></div>
                </div>
                <div v-else>
                    <div class="eventTitleUitverkocht" v-html="title"></div>
                </div>
            </div>
            <div v-else-if="(hiddenTicketTypes + ticketTypesSoldOut) == ticketTypes.count">
                <div class="eventTitleUitverkocht" v-html="title"></div>
            </div>
            <div v-else>
                <div class="eventTitle" v-html="title"></div>
            </div>
            
            <div class="button-box">
                <div v-if="soldOut || statusOverrule == 2">
                    <div v-if="allowRegister">
                        <button class="btn btnOrange wachtlijstbutton">{{translateString('General', "zetMeOpDeWachtlijst")}}</button>
                    </div>
                    <div v-else>
                        <div class="eventPriceUitverkocht">{{translateString('HomePage', 'prijs')}} {{getEventPriceRange(minPrice,maxPrice)}}</div>
                    </div>
                </div>
                <div v-else-if="statusOverrule == 1">
                    <div class="eventPrice">{{translateString('General', "beschikbaarVanaf")}}: {{getDateAndTimeFormatted(statusOverruleDate, 1, true, false)}} {{translateString('General', "om")}} {{getDateAndTimeFormatted(statusOverruleDate, 1, false, true)}}</div>
                </div>
                <div v-else-if="statusOverrule == 3">
                    <div class="eventPrice">{{translateString('General', "nietMeerBeschikbaar")}}</div>
                </div>
                <div v-else-if="hiddenTicketTypes != 0 && hiddenTicketTypes == ticketTypes.count">
                    <div class="eventPrice">{{translateString('General', 'GeenVerkoop')}}</div>
                </div>
                <div v-else-if="(hiddenTicketTypes + ticketTypesSoldOut) == ticketTypes.count">
                    <div class="eventPriceUitverkocht">{{translateString('HomePage', 'prijs')}} {{getEventPriceRange(minPrice,maxPrice)}}</div>
                </div>
                <div v-else>  
                    <div class="eventPrice">{{translateString('HomePage', 'prijs')}} {{getEventPriceRange(minPrice,maxPrice)}}</div>
                </div>

                <button class="btn btnWhite infobutton" v-on:click="goToEventInfoPage(id)">{{translateString('HomePage', 'info')}}</button>

                <div v-if="soldOut || statusOverrule == 2">
                    <button class="btn btnRed uitverkochtbutton">{{translateString('General', "uitverkocht")}}</button> 
                </div>
                <div v-else-if="statusOverrule == 1 || statusOverrule == 3">
                    <button class="btn btnGrey uitgeschakeldeBestelbutton">{{translateString('HomePage', 'bestel')}}</button> 
                </div>
                <div v-else-if="hiddenTicketTypes != 0 && hiddenTicketTypes == ticketTypes.count">
                    <button class="btn btnGrey uitgeschakeldeBestelbutton">{{translateString('HomePage', 'bestel')}}</button> 
                </div>
                <div v-else-if="(hiddenTicketTypes + ticketTypesSoldOut) == ticketTypes.count">
                    <button class="btn btnRed uitverkochtbutton">{{translateString('General', "uitverkocht")}}</button>
                </div>
                <div v-else>
                    <button class="btn btnGreen bestelbutton" v-on:click="openPopUp(id, 'checkout')">{{translateString('HomePage', 'bestel')}}</button> 
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
import router from '../router/index.js';
import {Functions} from '../util/util.js';
import {Selector} from '../language/language.js';
import BestelContainer from './Checkout/views/BestelContainer.vue';
import {store} from '../store/index.js';

export default {
    name: 'EventCard',
    setup() {
        return {
            hiddenTicketTypes: 0,
            ticketTypesSoldOut: 0,
        }
    },
    props: {
        id: {
            type: String
        },
        title: {
            type: String
        },
        image: {
            type: String
        },
        minPrice: {
            type: Number 
        },
        maxPrice: {
            type: Number 
        },
        dateFrom: {
            type: String
        },
        dateUntil: {
            type: String
        },
        soldOut: {
            type: Number
        },
        allowRegister: {
            type: Number
        },
        showDatesOptions: {
        },
        cardtype: {
        },
        periodLabel: {
        },
        statusOverrule: {
        },
        statusOverruleDate: {
            type: String
        },
        ticketTypes: {

        }
    },
    components: {
        BestelContainer
    },
    methods: {
      goToEventInfoPage(id) {

           if (store.state.shopNumber!="0") {
             router.push({ name: "EventInfoWithShopNumber", params: {id: id, shopNumber:'shop-'+store.state.shopNumber} });
           } else {
             router.push({ name: "EventInfo", params: {id: id}});
           }

        },
        openPopUp(id, namePopUp) {
            // body veranderen om te zorgen dat de background niet scrollable is
            // blijft ook op de positie waar die is geopend
            // scrollPosition ook opslaan
            const $body = document.querySelector('body');
            let scrollPosition = 0;
            scrollPosition = window.pageYOffset;
            $body.style.overflow = 'hidden';
            $body.style.position = 'fixed';
            $body.style.top = `-${scrollPosition}px`;
            $body.style.width = '100%';
            store.dispatch('updateScrollPosition', scrollPosition);

            store.dispatch('changeStep', 'bestellen');
            // open popup

            var selectedEvent;
            Object.keys(store.state.events.events.items).forEach(key => {
            if(store.state.events.events.items[key].ID == id) {
                return selectedEvent = store.state.events.events.items[key]
            }
            });

            store.dispatch('openPopUp', {selectedEvent: selectedEvent, popUpOpenByShoppingCartInfo: false, whichPopUpToOpen: namePopUp});
            //router.push({ name: "BestelContainer"});
        },  
        getDateAndTimeFormatted(date, dateOption, returnDate, returnTime){
            if(dateOption == 0) {
                var lengthWeekday = "short";
                var lengthMonth = "long";
            }
            if(dateOption == 1 || dateOption == 2) {
                var lengthWeekday = "short";
                var lengthMonth = "short";
            }
              
            if(date == '') {
                return "null"
            } else {
                return Functions.formatDateTime(date, lengthWeekday, lengthMonth, returnDate, returnTime);
            }        
        },
        getEventPriceRange(minPrice,maxPrice){

            if (minPrice!=maxPrice) {
                return Functions.formatMyCurrency(minPrice) + " - " + Functions.formatMyCurrency(maxPrice);
            } 

            if (minPrice==0) {
                return "** "+this.translateString('General', "gratis")+" **";
            }
            
            return Functions.formatMyCurrency(minPrice);
        },
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        checkIfThereIsSale(ttSoldOut, ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption) {
            if(ttVisible == 0) { 
                if(this.hiddenTicketTypes < this.ticketTypes.count) {
                    this.hiddenTicketTypes++;
                }            
                return;
            }

            // pak datum van vandaag
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTimeToday = date+' '+time;

            // zet dateTimeToday, dateFrom en dateUntil als Date()
            var newDateToday = new Date(dateTimeToday);
            var newDateFrom = new Date(dateFrom);
            var newDateUntil = new Date(dateUntil);
    
            // als de datum en tijd binnen de salesdatums vallen
            // dan is er niks aan de hand en mag de prijs gewoon op het scherm komen
            // dus geef null terug
            if(newDateToday.getTime() >= newDateFrom.getTime() && newDateToday.getTime() <= newDateUntil.getTime()) {
                if(ttSoldOut == 1) {
                    this.ticketTypesSoldOut++; 
                }
                return;
            }

            // 
            if(newDateToday.getTime() < newDateFrom.getTime()) {
                if(dateFromOption == 0) { 
                    if(this.hiddenTicketTypes < this.ticketTypes.count) {
                        this.hiddenTicketTypes++;
                    }                  
                    return;
                }     
            }
            else if(newDateToday.getTime() > newDateUntil.getTime()) {
                if(dateUntilOption == 0) {
                    if(this.hiddenTicketTypes < this.ticketTypes.count) {
                        this.hiddenTicketTypes++;
                    } 
                    return;
                }
                if(dateUntilOption == 2) {
                    this.ticketTypesSoldOut++;    
                    return;
                }
            }
        },
    },
    created() {
      for(var i = 0; i < this.ticketTypes.count; i++) {
          var ttSoldOut = this.ticketTypes.items[i].ttSoldOut;
          var ttVisible = this.ticketTypes.items[i].ttVisible;
          var ttDateFrom = this.ticketTypes.items[i].ttSalesFrom;
          var ttDateFromOption = this.ticketTypes.items[i].ttSalesFromOption;
          var ttDateUntil = this.ticketTypes.items[i].ttSalesUntil;
          var ttDateUntilOption = this.ticketTypes.items[i].ttSalesUntilOption;
          this.checkIfThereIsSale(ttSoldOut, ttVisible, ttDateFrom, ttDateFromOption, ttDateUntil, ttDateUntilOption);
      }
  },
  beforeUnmount() {
        this.hiddenTicketTypes = 0
  },
}
</script>

<style scoped>
.eventCard {
    width: 300px;
    height: 420px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 30px 60px 30px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    color: rgb(46, 46, 46);
}

.eventInfoCntr {
    padding:20px 10px 20px 20px;
}
.eventImage {
    width: 300px;
    height: 200px;
    object-fit: cover;
    object-position: 50% 50%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;;
}

.eventTitle, .eventTitleUitverkocht {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    
    overflow: hidden;
    max-height:55px; 


    position: relative;
    overflow: hidden;
    font-size: 21px;
    text-align: left;
    margin-top:8px;
    line-height: 24px;
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}

.eventTitleUitverkocht {
    color: lightgray;
}

.eventDate {
    font-weight: bold;
    color: #f09200;
    text-align: left;
    margin-top:0px;
    font-family: 'Didact Gothic', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
}

.eventDate:first-letter {
    text-transform: uppercase;
}

.eventPrice {
    text-align: left;
    font-size: 14px;
    font-family: 'Didact Gothic', sans-serif;  
    margin-bottom:15px;  
}
.eventPriceUitverkocht {
    text-align: left;
    font-size: 14px;
    font-family: 'Didact Gothic', sans-serif;  
    margin-bottom:15px;  
    color: #d3d3d3;
}

.wachtlijstbutton {  
    width: 100%;
    padding: 5px 2px 5px 2px;
    margin-bottom:5px;
}

.button-box {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    padding: 0px 20px 20px 20px;
}

.infobutton, .bestelbutton, .uitverkochtbutton, .uitgeschakeldeBestelbutton {
    width: 45%;
}
.infobutton {
    float: left;
}
.bestelbutton, .uitgeschakeldeBestelbutton, .uitverkochtbutton {
    float: right;
}

@media(max-width: 450px) {
    .eventCard {
    max-width:1100px;
    height: 400px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 30px 60px 30px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}
}
</style>