<template>
    <div class="maskseatpopup" @click="btnClose"> </div>
        <div class="seatingscreenPopup">
            <h2>{{ translateString("SeatingPlanPopup","Kies een tickettype voor deze stoel") }}:</h2>
            <select class="selector" v-model="selectedtype">
            <option v-for="tickettype in tickettypes" :key="tickettype.ticketTypeID" :value="tickettype.ticketTypeID">{{tickettype.title}} - {{tickettype.price}}</option>
        </select>
        <div class="buttons">
            <button @click="btnClose" class="btn btnWhite"> {{ translateString("SeatingPlanPopup","annuleren") }} </button>
            <button @click="btnBuy" class="btn btnGreen">  {{ translateString("SeatingPlanPopup","OK") }}  </button>
        </div>
    </div>
</template>

<script>
import {store} from '../store/index.js';
import {Selector} from '@/language/language.js';

export default {
    data(){
        return{
            seatnumb: null,
            seatrank: null,
            tickettypes: null,
            selectedtype: null
        }
    },
    mounted(){
        this.seatnumb = this.selectedSeat.seatnumb;
        this.seatrank = this.selectedSeat.seatrank;
        this.tickettypes = this.selectedSeat.tickettypes;
        //default bepalen
        this.tickettypes.forEach(tickettype => {
            if (tickettype.ticketTypeID == this.defaultTicketType) {
                this.selectedtype = this.defaultTicketType;
            } else if(this.selectedtype != this.defaultTicketType){
                this.selectedtype = this.tickettypes[0].ticketTypeID;
                console.log("selected type: "+this.selectedtype)
            }
        });
    },
    props:{
        selectedSeat: {

        },
        defaultTicketType: {

        }
    },
    methods:{
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        btnBuy() {
            //selectie door geven
            this.$emit('commitseat',{seatnumb: this.seatnumb, seatrank: this.seatrank, tickettype: this.selectedtype})
        },
        btnClose() {
            //pop-up sluiten zonder selectie
            store.commit("SAVE_SEATPOPUPSHOW", false);
        }
    }
}
</script>

<style scoped>
.maskseatpopup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    background-color: white;
}
.seatingscreenPopup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -85%);
    width: 400px;
    height:220px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.4);  
    padding-top:20px;  
}
.buttons {
    width:100%;
}
button {
    display: inline-block;
    width:150px;
    margin-left:5px;
    margin-right:5px;
}
.selector {
    background: transparent;
    outline: 0;
    color: rgb(46, 46, 46);
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: 0 0 0 1px rgb(255 255 255 / 25%);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 285px;
    background-position-y: 0px;

    width:310px;
    margin-top:40px;
    margin-bottom: 20px;
    border-radius: 4px;
    padding:5px 5px;
}
</style>