<template>
    <div class="paymentMethod" v-bind:class="{isSelected: isSelected}">
        <img class="paymentMethodImage" :src="image" />
        <div class="paymentMethodInfoCntr">
            <div class="paymentMethodName">{{name}}</div>
            <div v-if="issuers.length != 0" v-on:click.stop="">
                <div v-if="isSelected">
                    <div class="select-container select-container-top-arrow" style="width:100%;">
                      <select v-model="selectedIssuer" @change="changeSelectedIssuer()" class="select-top-arrow">
                          <option :value="''" >{{ translateString("Kies ...") }}</option>
                          <option v-for="option in optionsIssuers" :value="option.key" :key="option.key">{{option.name}}</option>
                      </select>
                      <span class="hint" style="position:absolute; left:10px; top:4px; color:gray; font-size:9px;">{{ getHint() }}</span>
                    </div>
                </div>
                <div v-else>
                    <div v-if="state.hasPaymentTransactionCost == 1">
                        {{costDisplay}}
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="state.hasPaymentTransactionCost == 1">
                    {{costDisplay}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from '@/store/index.js'
import {Selector} from '@/language/language.js';
export default {
    data() {
        return {
            optionsIssuers: [],
            selectedIssuer: '',
        }
    },
    setup() {
        return {
            state: store.state
        }  
    },
    props: {
        name: {

        },
        paymentKey: {

        },
        image: {

        },
        issuers: {

        },
        costDisplay: {

        },
        isSelected: {
            default: false
        },
    },
    methods: {
        populateSelector() {
            if(this.issuers.length > 0) {
                //this.optionsIssuers[0] = {name: this.translateString('KiesUwBank'), key: '', image: ''}
                for (var i = 0; i < this.issuers.length; i++) {

                    this.optionsIssuers[i] = {name: this.issuers[i].Name, key: this.issuers[i].Key, image: this.issuers[i].Image}

                }
            }

            this.selectedIssuer = '';
            // opzoeken laatste gebruikte
            for (const [mkey, method] of Object.entries(store.state.paymentMethods)) {
              if (mkey === this.paymentKey) {
                this.selectedIssuer =  method.selectedIssuer;
              }
            }

        },
        changeSelectedIssuer() {

           // opslaan in de paymentMethods zelf.
          // loop doo store.state.paymentMethods
          Object.entries(store.state.paymentMethods).forEach(([key, method]) => {
             if (key ===this.paymentKey)  {
               store.state.paymentMethods[method.Key].selectedIssuer = this.selectedIssuer;
             }
          });

          // emit de  changeIssuer
          this.$emit('changeIssuer', this.selectedIssuer);

        },
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated)
            return translatedString;
        },
        getHint() {

           // indien name 'cadeau' bevat dan de hint aanpassen
           if(this.name.includes('Cadeau')) {
             return this.translateString('Kaart ...');
           } else {
             return this.translateString('Bank ...');
           }
        }
    },
    mounted() {
        this.populateSelector();

        // Ophalen eventuele defautl issuer
        this.selectedIssuer = '';
        Object.entries(store.state.paymentMethods).forEach(([key, method]) => {
          if (this.paymentKey === method.Key)  {
            this.selectedIssuer = store.state.paymentMethods[key].selectedIssuer;
          }
        });

        if (this.selectedIssuer == '') {
           var local = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
           if (this.paymentKey == local.Key) {
             this.selectedIssuer = local.selectedIssuer;
           }
        }


    },
    watch: {
        isSelected(val, oldVal) {
            // de nieuwe waarde van isSelected true is, ga door deze functie
            if(val == true) {
                // check of de functie issuers heeft
                if(this.issuers.length > 0) {
                    if(this.selectedIssuer != '')  {
                        // check of de geselecteerde issuer deel uitmaakt van deze method
                        for(var i = 0; i < this.optionsIssuers.length; i++) {
                            // zoja, toevoegen
                            if(this.selectedIssuer == this.optionsIssuers[i].key) {
                            //    this.changeSelectedIssuer();
                                return; 
                            }
                        }          
                    }    
                }         
            }
        },
    }
}
</script>

<style scoped>
.isSelected {
    border: 1px solid #1aa8e2;
    box-shadow: 2px 2px 2px 2px #1aa8e2;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}
.paymentMethod {
    width: 150px;
    height: 165px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 0px 40px 0px;
    /*box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;*/
    position: relative;
    color: rgb(46, 46, 46);
    text-align: center;
}
.paymentMethodImage {
    margin-top: 10px;
    width: 100px;
    border-radius: 10px;
}
.paymentMethodInfoCntr {
    padding: 0px 10px 10px 10px;
}
.paymentMethodName {
    font-weight: bold;
    font-size: 14px;
}
</style>