<template>
    <div v-if="state.isLoaded">
        <Checkout></Checkout>
        <SeatingPlan v-if="state.seatingshow"></SeatingPlan>
    </div>        
</template>

<script>
import {store} from '@/store/index.js'
import Checkout from '@/components/Checkout/CheckoutPopUp.vue'
export default {
    name: "ClientShop",
    setup() {
        return {
            state: store.state
        }
    },        
    components: {
        Checkout
    },
    mounted() {

        //store.dispatch('changeStep', 'bestellen');
        /*
       // store.dispatch('changeStep', 'klantinformatie');
       // opzoeken eventID
        const eventID = 3176;
        var selectedEvent = null
       this.state.events.events.forEach( item =>  {
           if (item.eventID==eventID) {
               selectedEvent = item;
                exit;
           }
       }); 

        if (selectedEvent!=null) {
            store.dispatch('openPopUp', {selectedEvent: selectedEvent, popUpOpenByShoppingCartInfo: false, whichPopUpToOpen: "availability"});
        }
        */
    }
}
</script>

<style scoped>
.directOrderCheckout {
    margin-top: 80px;
}
</style>