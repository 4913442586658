import { createRouter, createWebHistory } from 'vue-router';
import Test from '@/views/Test.vue';
import Home from '../views/Home.vue'
import ClosedShop from '../views/ClosedShop.vue';
import ShopDoesNotExist from '../views/ShopDoesNotExist.vue';
import EventInfo from '../views/EventInfo.vue';
import ProcessPayment from '../views/ProcessPayment.vue';
import OrderResult from '../views/OrderResult.vue';
import DirectOrder from '../views/DirectOrder.vue';
import DirectCart from '../views/DirectCart.vue';
import PersonalShop from '../views/PersonalShop.vue';
import ClientShop from '../views/ClientShop.vue';
import {store} from '../store/index.js'
import QueueBusy from "@/views/QueueBusy.vue";

const routes = [
  { path: '/closed', name: 'Closed', component: ClosedShop },
  { path: '/busy', name: 'Busy', component: QueueBusy },
  { path: '/shopDoesNotExist', name: 'ShopDoesNotExist', component: ShopDoesNotExist },
  { path: '/test', name: 'Test', component: Test },
  { path: '/event/:id', name: 'EventInfo', component: EventInfo, props: true },
  { path: '/processpayment', name: 'ProcessPayment', component: ProcessPayment },
  { path: '/orderresult/:status', name: 'OrderResult', component: OrderResult, props: true },
  { path: '/directorder', name: 'DirectOrder', component: DirectOrder },                      /* Bestelling vanuit de boxoffice */
  { path: '/directcart/:id', name: 'DirectCart', component: DirectCart, props: true },
  { path: '/personalshop', name: 'PersonalShop', component: PersonalShop },
  { path: '/clientshop', name: 'ClientShop', component: ClientShop },

  { path: '/:shopNumber/event/:id', name: 'EventInfoWithShopNumber', component: EventInfo, props: true },
  { path: '/:shopNumber/closed', name: 'ClosedWithShopNumber', component: ClosedShop, props: true },
  { path: '/:shopNumber/orderresult/:status', name: 'OrderResultWithShopNumber', component: OrderResult, props: true },
  { path: '/:shopNumber/personalshop', name: 'PersonalShopWithShopNumber', component: PersonalShop, props: true },
  { path: '/:shopNumber/processpayment', name: 'ProcessPaymentWithShopNumber', component: ProcessPayment, props: true },
  { path: '/:shopNumber', name: 'HomeWithShopNumber', component: Home, props: true },

  { path: '/', name: 'Home', component: Home },

  // Alle overige paginas gaan naar 404
  {
    path: '/:pathMatch(.*)*', // Wildcard route voor 404
    name: 'NotFound',
    component: ShopDoesNotExist,
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  /*
  const { shopNumber, id } = to.params;

  if (shopNumber) {
    store.commit('SAVE_SHOPNUMBER', shopNumber);
  }
   */
  next();
});

/*router.beforeEach((to, from, next) => {
  window.scrollTo(0,0);
})*/

export default router