<template>
    <div class="checkout" v-if="state.isLoaded" :class="isClientPage">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @closeAlert="closeAlert"></Alert>

        <div class="checkOutColumns">
            <div class="stappenPaginas">
              <div v-if="state.step == 'changeoverzicht'">
                <div class="customerFormDirectOrderMessage" v-if="state.isDirectOrder" ><span>{{ this.getAfrondenTXT1() }}</span><span>{{ this.getAfrondenTXT2() }}</span></div>
                <div class="stapCntr bestelContainer">
                  <MobileCartContainer></MobileCartContainer>
                </div>
              </div>
              <div v-if="state.step == 'bestellen'">
                    <div class="stapCntr bestelContainer">
                        <BestelContainer></BestelContainer>
                    </div>
                </div>
                <div v-if="state.step == 'klantinformatie'">
                  <div class="customerFormDirectOrderMessage"  v-if="state.isDirectOrder" ><span>{{ this.getAfrondenTXT1() }}</span><span>{{ this.getAfrondenTXT2() }}</span></div>
                  <div class="stapCntr customerFormWrap">
                        <CustomerForm></CustomerForm>
                    </div>
                </div>
                <div v-if="state.step == 'betalen'">
                  <div class="customerFormDirectOrderMessage"  v-if="state.isDirectOrder" ><span>{{ this.getAfrondenTXT1() }}</span><span>{{ this.getAfrondenTXT2() }}</span></div>
                    <div class="stapCntr paymentForm">
                        <PaymentForm></PaymentForm>
                    </div>
                </div>
                <div v-if="state.step == 'overzicht'">
                  <div class="customerFormDirectOrderMessage"  v-if="state.isDirectOrder" ><span>{{ this.getAfrondenTXT1() }}</span><span>{{ this.getAfrondenTXT2() }}</span></div>
                  <div class="stapCntr overzicht">
                    <MobileOverviewContainer></MobileOverviewContainer>
                  </div>
                </div>
                <div v-if="state.step == 'voorwaarden'">
                    <div class="stapCntr terms">
                        <Terms></Terms>
                    </div>
                </div>
                <div v-if="state.step == 'personaliseren'">
                    <div class="stapCntr personaliseren">
                        <TicketsPersonaliseren></TicketsPersonaliseren>
                    </div>
                </div>
            </div>

            <div id="shoppingCart" class="shoppingCart" v-if="showShoppingCart()" >
                <ShoppingCart></ShoppingCart>
            </div>
        </div>
        <div style="clear:both;"></div>
      <div v-if="this.isClientPage">
        <div style="text-align: center; font-size: 12px;">
          <br />
          Powered By TicketView (&copy;) | <a href="https://www.ticketview.nl/mvc/assets/pdf/algemene_voorwaarden.pdf">{{translateString('Footer', 'AlgemeneVoorwaarden')}}</a> <!--| <a href="#">{{translateString('Footer', 'Disclaimer')}}</a> --><br />
          <br />
        </div>
      </div>
    </div>
</template>

<script>
import BestelContainer from './views/BestelContainer.vue';
import CustomerForm from './views/CustomerForm.vue';
import ShoppingCart from './views/ShoppingCart.vue';
import PaymentForm from './views/PaymentForm.vue';
import Terms from './views/Terms.vue';
import TicketsPersonaliseren from './views/TicketsPersonaliseren.vue';
import {Functions} from '@/util/util.js';
import {store} from '@/store/index.js';
import Alert from '@/components/Alert.vue';
import {Selector} from "@/language/language.js";
import ShoppingList from "@/components/Checkout/components/ShoppingList.vue";
import MobileOverviewContainer from "@/components/Checkout/views/MobileOverviewContainer.vue";
import MobileCartContainer from "@/components/Checkout/views/MobileCartContainer.vue";

export default {
    name: 'Checkout',
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            alertTitle: '',
            alertText: '',
            alertType: '',
            active: false,
            alertTime: null,
            okButtonActive: 0,
            gereserveerdeBestellingTXT: 'Afronden gereserveerde bestelling.'
        }
    },
    components: {
      MobileCartContainer,
      MobileOverviewContainer,
      ShoppingList,
        BestelContainer,
        CustomerForm,
        ShoppingCart,
        PaymentForm,
        Terms,
        TicketsPersonaliseren,
        Alert
    },
    methods: {
      translateString(componentOrPageName, stringNotTranslated) {
        var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
        return translatedString;
      },
      getAfrondenTXT1() {
        return this.translateString('Checkout', 'GereserveerdeBestelling') + ": " + store.state.loadCartResult.shopReservationOrderRef;
      },
      getAfrondenTXT2() {
        var hulp = Functions.formatDateTime(store.state.loadCartResult.shopReservationUntil,'short','short',true,true,4);
        return this.translateString('Checkout', 'GeldigTot') + " " + hulp;
      },

      closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        },
        showShoppingCart() {
          // Altijd laten zien, behalve bij overzicht en changeoverzicht
          if (this.state.step == 'changeoverzicht' || this.state.step == 'overzicht') {
            return false;
          } else {
            return true;
          }
        }
    },
    computed: {
        isClientPage() {
            if(this.state.isClientPage) {
                return 'clientPage';
            } else {
                return '';
            }
        }
    },
    mounted() {   
        if(store.state.popUpOpenByShoppingCartInfo == false && store.state.step == 'klantinformatie') {
            this.step = 'bestellen';
            store.dispatch('changeStep', this.step)
        } 
        
    },
}
</script>

<style scoped>
.checkout {
    width: 100%;
    overflow-x:hidden;
    margin-bottom:50px;
    padding-bottom: 20px;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    /* box-shadow: 2px 2px 2px 2px grey; */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    font-family: 'Didact Gothic', sans-serif;
}

.clientPage {
  box-shadow: none!important;
  border:1px solid darkgray;
}

.checkOutColumns {
    width:100%;
    display:flex;
    flex-direction: row;
}

.stappenPaginas {
    flex-grow: 1;
}

.shoppingCart {
    flex-basis: 300px;
}


.bestelContainer, .customerFormWrap, .paymentForm, .terms, .personaliseren {
    width:100%;
    border-top-left-radius: 10px;
}

.customerFormDirectOrderMessage {
  background-color: whitesmoke;
  padding: 20px 10px 10px 10px;
  border-bottom: dashed 1px lightgray;
}
.customerFormDirectOrderMessage span:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  display: block;
}
.customerFormDirectOrderMessage span:nth-child(2) {
  font-size: 13px;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 767px) {
    #shoppingCart {
        display: none;
    }
}


</style>