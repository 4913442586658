<template>
  <div v-if="state.isLoaded" style="margin-top:-60px;">
        <Alert :title="alertTitle" :text="alertText" :active="active" :alertType="alertType" :okButtonActive="okButtonActive"    @closeAlert="closeAlert"></Alert>
        <Checkout v-if="hasCart" class="directOrderCheckout"></Checkout>
  </div>
</template>

<script>
import {store} from '@/store/index.js'
import Checkout from '@/components/Checkout/CheckoutPopUp.vue'
import HeaderBackground from "@/components/HeaderBackground.vue";
import TextFit from "@/components/TextFit.vue";
import Flag from "@/components/Flag.vue";
import Footer from "@/components/Footer.vue";
import Alert from "@/components/Alert.vue";
import {Selector} from "@/language/language.js";
import {Functions} from '@/util/util.js';
export default {
  components: {
    Alert,
    Footer,
    Flag, TextFit,
    HeaderBackground,
    Checkout
  },
  methods: {
    closeAlert(close) {


      const message = {
        type: 'close'
      };
      window.parent.postMessage(message, document.referrer);

    },
  },
  data() {
    return {
      alertTitle: 'Er zijn nog geen tickets gekozen',
      alertText: 'Kies eerst tickets ....',
      alertType: 2,
      active: false,
      okButtonActive: 1,
      alleenEersteKeer: true
    }


  },
  setup() {
    return {
      state: store.state
    }
  },
  created() {
    store.dispatch('changeStep', 'klantinformatie');
  },
  computed: {
    hasCart() {
      // eerst kijken of de shop wel open is.
      if (Functions.isShopClosed()) {
        return false;
      }

      if (localStorage.getItem("fromEmptyCart") == "true") {
        localStorage.setItem("fromEmptyCart", "false");
        return true; // Doe verder niets.
      }

      if (store.state.cart.bestelItems.length > 0) {
        return true;
      } else {
        this.active = true;
        return false;
      }
    }
  },
  mounted() {
      store.state.popUpOpenByShoppingCartInfo = true;
      store.dispatch('changeStep', 'klantinformatie');
  }
}
</script>

<style scoped>
.directOrderCheckout {
  margin-top: 80px;
}

.home {
  width:100%;
  height:100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background-color: transparent;
}

.wrapper {
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 1;
}

.inner {
  max-width:1200px;
  margin:auto;
  justify-items: center;
  align-items: center;
  position: relative;
}

Footer {
  flex-shrink: 0;
}



</style>