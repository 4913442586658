<template>
    <div class="TicketSelector">
        <div class="ticketTypeWrapper">
            <div class="ticketTypeNameAndPriceWrapper">
                <div class="ticketTypeName">
                    <!-- Check of het tickettype wel getoond moet worden, als deze '' teruggegeven moet het tickettype niet op het scherm -->
                    <div v-if="checkDateTimeTicketSales(ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == 0">
                    </div>
                    <div v-else-if="checkDateTimeTicketSales(ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == ''">
                    </div>
                    <div v-else-if="checkDateTimeTicketSales(ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == 2">
                        <div class="ticketUitverkocht" v-html="getStringInTheRightLanguage(ticketType.ttTitle.items)"></div> 
                    </div>
                    <!-- Anders laat het tickettype zien -->
                    <div v-else>
                        <div>
                          <div style="display: inline-block" v-html="getStringInTheRightLanguage(ticketType.ttTitle.items)"></div>
                          <div v-if="ticketType.ttMinAge>0" style="display: inline-block; color:red">&nbsp;{{ticketType.ttMinAge}}+</div>
                          <div v-if="ticketType.ttHasCheckoutFields>0" style="display: inline-block">&nbsp;**</div>
                        </div>
                    </div>
                </div>
                <div class="ticketTypePrice">
                    <div v-if="ticketType.ttSoldOut == 0">
                    <!-- als de functie checkDateTimeTicketSales null teruggeeft, kan de prijs gewoon op het scherm-->
                        <div v-if="checkDateTimeTicketSales(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == null">
                            {{getMyCurrencyFormatted(ticketType.ttPrice)}}
                        </div>   
                        <!-- als de functie checkDateTimeTicketSales '' teruggeeft, moet het tickettype niet getoond worden-->
                        <div v-else-if="checkDateTimeTicketSales(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == ''">
                        </div>
                        <!-- als de functie checkDateTimeTicketSales 2 teruggeeft, moet het tickettype als uitverkocht getoond worden-->
                        <div v-else-if="checkDateTimeTicketSales(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == 2">
                            <div class="labelUitverkocht">{{translateString('uitverkocht')}}</div>
                        </div>                                               
                        <!-- als de functie checkDateTimeTicketSales iets anders teruggeeft dan null, 0 of 2, dan laat dit op het scherm zien--> 
                        <div v-else>
                            {{checkDateTimeTicketSales(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption)}}
                        </div> 
                    </div>
                    <div v-if="ticketType.ttSoldOut == 1">
                        <div class="labelUitverkocht">{{translateString('uitverkocht')}}</div>
                    </div>
                </div> 
            </div>
            <div class="ticketSelectorContainer"> 
                
                <div v-if="seatingPlanID>0">
                        <div class="exseats">
                            <button class="btn btnWhite" v-on:click="btnSeats()"> {{translateString('KiesJeStoelen')}}</button>
                        </div>
                </div>

                <div v-else-if="ticketType.ttSoldOut == 1">
                    <div class="ticketSelectorWrapper">
                      <div class="select-container">
                        <select :id="'select'+ticketType.ID" ref="select" class="selector" disabled @change="changeSelectedOption()">
                            <option v-for="option in maxTickets" :value="option.id" :key="option.id">{{option.name}}</option>
                        </select>
                        </div>
                        <div class="ticketTypeCalculatedPriceSoldOut">{{getMyCurrencyFormatted(calculatedPrice)}}</div>
                    </div>
                </div>
                
                <div v-else-if="checkDateTimeTicketSales(ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption) == null">
                    <div class="ticketSelectorWrapper">
                      <div class="select-container">
                        <select v-model="selected" :id="'select'+ticketType.ID" :disabled="checkArTSTT()" class="selector" @change="changeSelectedOption()">
                            <option v-for="option in maxTickets" :value="option.id" :key="option.id">{{option.name}}</option>
                        </select>
                      </div>
                        <div class="ticketTypeCalculatedPrice">{{getMyCurrencyFormatted(calculatedPrice)}}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="ticketSelectorWrapper">
                      <div class="select-container">
                        <select :id="'select'+ticketType.ID" ref="select" class="selector" disabled @change="changeSelectedOption()">
                            <option v-for="option in maxTickets" :value="option.id" :key="option.id">{{option.name}}</option>
                        </select>
                        </div>
                        <div class="ticketTypeCalculatedPriceSoldOut">{{getMyCurrencyFormatted(calculatedPrice)}}</div>
                    </div>
                </div>
            </div>                            
                <!--<div id="ticketQuantityCounter">å
                    <button class="decQuantityBtn" v-on:click="decrementTickets(ticketType.ID)">-</button>
                    <input class="inputTicketQuantity" type="text" :value="quantity">
                    <button class="incQuantityBtn" v-on:click="incrementTickets(ticketType.ID)">+</button>
                    </div>
                <h5>---------------</h5>-->
        </div>
    </div>
</template>

<script>
import {Functions} from '@/util/util.js';
import {Selector} from '@/language/language.js';
import {store} from '@/store/index.js';
export default {
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            optionsSelector: [],
            calculatedPrice: 0,
            selected: '0',
            text: ''
        }   
    },
    props: {
        ticketType: {

        },
        besteld: {

        },
        seatingPlanID: {

        }
    },
    computed: {
        maxTickets() {
            this.optionsSelector = []
            var maxTickets = this.ticketType.ticketsMaxPerOrder

            if(this.state.selectedEvent.evHasTimeSlots == 1){
                if(store.state.currentSlot.available != undefined) {    
                    maxTickets = Math.min(this.ticketType.ticketsMaxPerOrder, store.state.currentSlot.available)
                }
            }
            
            for (var i=0;i<=maxTickets;i++){
                this.optionsSelector[i] = {name: i, id: i}
            }  
            return this.optionsSelector
        }              
    },
    methods: {
        btnSeats(){
            //opent het stoelenplan
            store.dispatch('getSeats');
            store.commit("SAVE_DEFAULT_TICKETTYPE_SEATINGPLAN", this.ticketType.ID)
            store.commit("SAVE_SEATINGSHOW", true)
        },

        getStringInTheRightLanguage(arStrings) {
            var string = Selector.SelectLanguageStringFromArray(arStrings)
            return string;
        },
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
        resetSelector() {
            this.selected = '0';
            this.calculatedPrice = 0;
        },
        changeSelectedOption () {
            var selectedOption = this.selected
            var price = (selectedOption * this.ticketType.ttPrice)
            this.calculatedPrice = price;
            // tickettype id
            var id = this.ticketType.ID;

            // naam tickettype
            var ticketName = this.getStringInTheRightLanguage(this.ticketType.ttTitle.items)

            // prijs van een ticket
            var ticketPrice = this.ticketType.ttPrice;

            // eventueel pakketItemID
            var pakketItemID = this.ticketType.pakketItemID;

            // hoeveelheid tickets
            var quantity = this.selected;

            var key = this.ticketType.uniqueKey

            // geef de nodige gevens terug aan de orderlist
            let res;
            this.$emit('selected', {id, price, ticketName, ticketPrice, pakketItemID, quantity, key}, (x)=>res=x)
            
            this.text = res;
            if(this.text == "reset") {
                this.resetSelector();
            }
        },
        changeSelectedOptionIfBesteld (item) {
            var selectedOption = item.quantity
            var cumulatedPrice = (selectedOption * this.ticketType.ttPrice)
            this.calculatedPrice = cumulatedPrice;
            this.selected = selectedOption;
        },
        checkDateTimeTicketSales(ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption) {
            if(ttVisible == 0) { 
                return '';
            }

            // pak datum van vandaag
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTimeToday = date+' '+time;

            // zet dateTimeToday, dateFrom en dateUntil als Date()
            var newDateToday = new Date(dateTimeToday);
            var newDateFrom = new Date(dateFrom);
            var newDateUntil = new Date(dateUntil);
    
            // als de datum en tijd binnen de salesdatums vallen
            // dan is er niks aan de hand en mag de prijs gewoon op het scherm komen
            // dus geef null terug
            if(newDateToday.getTime() >= newDateFrom.getTime() && newDateToday.getTime() <= newDateUntil.getTime()) {
                return null;
            }

            // 
            if(newDateToday.getTime() < newDateFrom.getTime()) {
                if(dateFromOption == 0) {
                    return '';
                }
                if(dateFromOption == 1) {
                    return this.translateString('beschikbaarVanaf')+" "+this.getDateAndTimeFormatted(dateFrom, true, false)+" "+this.translateString('om')+" "+this.getDateAndTimeFormatted(dateFrom, false, true);
                }       
            }
            else if(newDateToday.getTime() > newDateUntil.getTime()) {
                if(dateUntilOption == 0) {
                    return '';
                }
                if(dateUntilOption == 1) {
                    return this.translateString('nietMeerBeschikbaar');
                }       
                if(dateUntilOption == 2) {
                    return 2;
                }
            }
        },
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('General', stringNotTranslated)
            return translatedString;
        },
        checkArTSTT() {
            // return true -> btn disabled
            // return false -> btn not disabled
            if(this.state.selectedEvent.evHasTimeSlots == 1){ 

                var arrayTicketTypeOptions = store.state.currentSlot.arTSTT;

                if(this.state.currentSlot.available == 0) {
                    return true;
                }      
                else if(arrayTicketTypeOptions != [] && arrayTicketTypeOptions.artt != undefined) {
                    // als de array leeg is, niks doen
                    if(arrayTicketTypeOptions.artt.length == 0) {
                        return false;
                    } else {
                        // als tto 0 is, niks doen dus return false
                        if(arrayTicketTypeOptions.tto == 0) {
                            return false;
                        } else {            
                            var ticketTypeID = this.ticketType.ID;
                            var ticketTypeIDWithoutTt = ticketTypeID.replace("tt", "");

                            // checken of dit tickettype in de artt voorkomt

                            var doesArrayIncludeTicketTypeID = arrayTicketTypeOptions.artt.includes(Number(ticketTypeIDWithoutTt));
                            if(doesArrayIncludeTicketTypeID) {
                               
                                if(arrayTicketTypeOptions.tto == 1) {
                                    // Wanneer tickettype voorkomt in array en tto is 1, aanzetten, dus return false
                                    return false;
                                } else if(arrayTicketTypeOptions.tto == 2) {
                                    // Wanneer tickettype voorkomt in array en tto is 2, uitzetten, dus return true
                                    return true;
                                }
                                
                            } else {
                                
                                if(arrayTicketTypeOptions.tto == 1) {
                                    // Wanneer tickettype NIET voorkomt in array en tto is 1, uitzetten, dus return true
                                    return true;
                                } else if(arrayTicketTypeOptions.tto == 2) {
                                    // Wanneer tickettype NIET voorkomt in array en tto is 2, aanzetten, dus return false
                                    return false;
                                }
                            }                     
                        }
                    }      
                }
            }
        }
    },
    mounted() {
        if(this.besteld != null) {
            this.changeSelectedOptionIfBesteld(this.besteld)
        }
        else {
            this.resetSelector();
        }
    },
    watch: {     
        besteld: function(newVal, oldVal) {
            if(newVal != undefined){
                if(this.besteld != null) {
                    this.changeSelectedOptionIfBesteld(this.besteld)
                }
            } else {
                this.resetSelector();
            }
        },
    }
}
</script>

<style scoped>
.ticketTypeWrapper {
    display: flex;
    flex-direction: row;
}

.ticketTypeNameAndPriceWrapper {
    flex: auto;
}
.ticketTypeName {
    font-size: 16px;
    --max-lines: 2;
    font-weight: bold;
    word-wrap: break-word;
}
.ticketUitverkocht {
    text-decoration: line-through;
}
.labelUitverkocht {
    text-transform: uppercase;
    color: red;
}

.ticketTypePrice {
    font-size: 16px;
    white-space: nowrap;
}

.ticketSelectorContainer {  
    width: 150px;
    min-width: 150px;
}
.ticketSelectorWrapper {
    height: 100%;
    padding: 10px 0 10px 20px;
}

/*.selector {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 2px;
}*/

.selector {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
    position: relative;
    border-radius: 4px;

    background: transparent;
    outline: none;
    color: rgb(46, 46, 46);
    height: 1.5rem;

    font-size: inherit;
   
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 21px;
    background-position-y: 0;
    padding: 0 0 0 8px;
    width: 44px;
}

.selector:disabled {
    background: rgb(220,220,220)
}

/*hide the items when the select box is closed:*/
.selector.select-hide {
  display: none;
}

.selector.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.ticketTypeCalculatedPrice {
    font-size: 16px;
    float: right;
}
.ticketTypeCalculatedPriceSoldOut {
    font-size: 16px;
    float: right;
    color: grey;
}
</style>