import $ from 'jquery';
import {store} from '../src/store/index.js';

var slots = {

	currentSlot: "",

//$(document).ready(function () {
initSlots: function(calHTML) {

	var newCal = $('<div />').html(calHTML);
	$(newCal).find('.timeSlotCalender').each(function(){

		if ($(this).find('[data-first-av]').length>0 && $(this).attr('data-start-slot')=="") {
			var dt = $(this).find('[data-first-av]').attr('data-first-av');
			var date1 = dt.substr(0,10);
			var tijd1 = dt.substr(11,5); 
			setTS($(this),date1,tijd1);
		}
		
		if ( $(this).attr('data-start-slot')!="") {
			var date = $(this).attr('data-start-slot').substr(0,10);
			var tijd = $(this).attr('data-start-slot').substr(11,5); 
			setTS($(this),date,tijd);
		}
	});

	$('.calendar').html("");
	$('.calendar').append(newCal);




	$('.tsnext').click(function(e){

		var parent = $(this).closest('.timeSlotCalender');

		parent.find('[data-volgnum]').each(function(){
			if ( $(this).is(":visible") ) {
				
				var num = $(this).attr('data-volgnum');
				num++;
				if (parent.find('[data-volgnum='+num+']').length>0) {
					$(this).hide();
					parent.find('[data-volgnum='+num+']').show();
					var label = parent.find('[data-volgnum='+num+']').attr('data-label');
					parent.find('.tslabel').html(label);
				}
				return false;
			}
		});
		resetTsKeuze(parent);
//		parent.find('.timeCntrInner').html("");
	//	parent.find('.tsdag').removeClass('selected');
		e.preventDefault();
		
	});

	$('.tsprev').click(function(e){
		var parent = $(this).closest('.timeSlotCalender');

		parent.find('[data-volgnum]').each(function(){
			if ( $(this).is(":visible") ) {
				
				var num = $(this).attr('data-volgnum');
				num--;
				if (num>0) {
					$(this).hide();
					parent.find('[data-volgnum='+num+']').show();	
					var label = parent.find('[data-volgnum='+num+']').attr('data-label');
					parent.find('.tslabel').html(label);
				}
				return false;
			}
		});
		resetTsKeuze(parent);
//		parent.find('.timeCntrInner').html("");
	//	parent.find('.tsdag').removeClass('selected');
		e.preventDefault();
	});
	
	$('.tsnoslots').click(function(){
		//$('.timeCntrInner').html('');
	});
	
	$('.tsdag').click(function(e){
		
		var parent = $(this).closest('.timeSlotCalender');
		
		// alleen indien een dag met sloten
		if ($(this).attr('data-slots').length==0 || $(this).attr('data-slots')=="") { 

			resetTsKeuze(parent);

			e.preventDefault(); 
			e.stopPropagation();  
			return;
		}

		parent.find('.timeCntrInner').html('');

		parent.find('.tsdag').removeClass('selected');
		$(this).addClass('selected');

		var over = $(this).attr('data-over').split(';');
		var slots = $(this).attr('data-slots').split(';');

		var str = '<ul>';		
		var curtijd = ""; 
		$('.meldingOver').hide();
		for(var tel=0; tel<slots.length; tel++) {
			var cl  = "";
			var cl2  = "";

			// over checken ...
			if (over.length==slots.length) {
				if (over[tel]<10) {
					cl2 = " almostSoldOut ";

				}
			}
			

			var tijd = slots[tel];
			var p = tijd.indexOf('|AV:');
			tijd = tijd.substr(0,p);


			if (tel==0) { 
				cl = " selected ";
				curtijd = tijd; 

				if (cl2!="") {
					if (over[tel]==1) {
						$('.meldingOver').html("Voor dit tijdslot is nog maar 1 ticket over!");
					} else {
						$('.meldingOver').html("Voor dit tijdslot zijn nog maar " + over[tel] + " tickets over!");
					}

					$('.meldingOver').show();
				}


			}
			str = str +  '<li class="'+cl+cl2+'">' + tijd + '</li>';				
		}
		str = str +  '</ul>';
		
		
		
		// toon tijdsloten
		parent.find('.timeCntrInner').hide();
		parent.find('.timeCntrInner').html(str);
		parent.find('.timeCntrInner').fadeIn();
		
		// selected dat
		var lbl = $(this).attr('data-label');
		parent.find('.tsfooter > label').html(lbl + " " + curtijd + " uur");
		setLabelTS(parent);
		
	});
	

	$('.timeCntrInner').on('click','ul > li',function(){
		var parent = $(this).closest('.timeSlotCalender');
		$('.timeCntrInner').find('li').removeClass('selected');
		$(this).addClass('selected');

		// opzoeken over
		$('.meldingOver').hide();

		var dagObj = parent.find('.tsdag.selected');

		var selDate = $(dagObj).data('date');
		var tijden = $(dagObj).data('slots');
		var over = $(dagObj).data('over');
		var arTijden = tijden.split(';');
		var arOver = over.split(';');
		var selTijd = $(this).html();

		if (arTijden.length==arOver.length) {
			for (var tel = 0; tel < arTijden.length; tel++) {
				//alert(arTijden[tel].substr(0,5) + "" + selTijd.substr(0,5));
				if (arTijden[tel].substr(0,5) == selTijd.substr(0,5)) {
					if (arOver[tel]<10) {
						if (arOver[tel]>1) {
							$('.meldingOver').html("Voor dit tijdslot zijn nog maar " + arOver[tel] + " tickets over!");
						} else {
							$('.meldingOver').html("Voor dit tijdslot is nog maar 1 ticket over!");
						}
						$('.meldingOver').show();
					}
					break;
				}
			}
		}

		setLabelTS(parent);
	});
	
	
	
	
	
	$('.timeHdr').click(function(e){
		var parent = $(this).closest('.timeSlotCalender');
		
		// zoek index van geselecteerd item
		var num = parent.find('.timeCntrInner > ul > li.selected').index();
		if (num>0) {
			num--; // num begint bij 0 en nth-child niet.....
			var nieuwe = parent.find('.timeCntrInner > ul > li:nth-child('+(num+1)+')');
			parent.find('.timeCntrInner > ul > li').removeClass('selected');
			$(nieuwe).addClass('selected');
			
			var offset = nieuwe.offset();	
			if (offset!=undefined) {
				parent.find('.timeCntrInner > ul').animate({
				    scrollTop: (offset.top*-1)
				}, 200);
			}
		}
		setLabelTS(parent);
		e.preventDefault();
	});

	
	$('.timeFtr').click(function(e){
		var parent = $(this).closest('.timeSlotCalender');
		
		// zoek index van geselecteerd item
		var num = parent.find('.timeCntrInner > ul > li.selected').index();
		var last = parent.find('.timeCntrInner > ul > li:last-child').index();
		if (num>=0 && num+1<=last) {
			num++; // num begint bij 0 en nth-child niet.....
			var nieuwe = parent.find('.timeCntrInner > ul > li:nth-child('+(num+1)+')');
			if (nieuwe!=last) {
				parent.find('.timeCntrInner > ul > li').removeClass('selected');
				$(nieuwe).addClass('selected');
				
				var offset = nieuwe.offset();	
				if (offset!=undefined) {
					parent.find('.timeCntrInner > ul').animate({
					    scrollTop: offset.top
					}, 200);
				}
			}
		}
		setLabelTS(parent);
		e.preventDefault();
	});
	
	
	function resetTsKeuze(parent) {		
		parent.find('.tsdag').removeClass('selected');
		parent.find('.timeCntrInner').html('');
		parent.find('.tsfooter > label').html('Selecteer een datum en tijd');	
		slots.currentSlot = "";
		store.dispatch('changeCurrentSlot', {slotFrom:'',available:0,arTSTT: []});
	}
	
	function setLabelTS(parent) {
		var dag = parent.find('.tsdag.selected');
		var tijd = parent.find('.timeCntrInner > ul > li.selected');

		
		if (dag.attr('data-label').length==0 || tijd.html()==undefined) {
			return;
		}
		
		// Selected dat
		var lbl = dag.attr('data-date');
		var curtijd = tijd.html();
		var tijdVanaf = String(curtijd).split('-')[0];
//		var tijdTot = String(curtijd).split('-')[1];

		// maak de string compleet, in het format yyyy-mm-dd hh-mm-ss
		var timeSlotFrom = lbl+" "+tijdVanaf+":00"
		//var timeSlotUntil = lbl+" "+tijdTot+":00"

		// opzoeken AV in array...
		var slots = dag.attr('data-slots').split(';');
		var AV = 0;
		for(var tel=0; tel<slots.length; tel++) {

			var tijd = slots[tel];
			var p = tijd.indexOf('|AV:');
			tijd = tijd.substr(0,p);
			var tijdV = tijd.split('-')[0];

			if(tijdV==tijdVanaf) {
				AV = slots[tel].substr(slots[tel].indexOf('|AV:')+4);
				break;
			}
		}

		var arTSTTHulp;
		if (typeof arTSTT !== 'undefined') {
			arTSTT.forEach(function(item){
				if (item.key+':00'==timeSlotFrom) {
					arTSTTHulp = item
					return;
				}
			});
		}


		store.dispatch('changeCurrentSlot',{
			slotFrom:timeSlotFrom,
			available:AV,
			arTSTT: arTSTTHulp
		});

		parent.find('.tsfooter > label').html(lbl + " " + curtijd + " uur");
	}
	
	function setTS(parent,date,tijd) {

		
			// zoek de dag op
			if (parent.find('[data-date="'+date+'"]:last').length==0) {
				return;
			}
			var dag = parent.find('[data-date="'+date+'"]:last');
			
			
			var maandBlok = dag.closest('.tsblok');

			// zorg dat de maand actied is
			if (!maandBlok.is(':visible')) {
				parent.find('[data-volgnum]').hide();
				maandBlok.show();

				// label header
				var label = maandBlok.attr('data-label');
				parent.find('.tslabel').html(label);
				
				
			}
			
			// selecteer dag 
			parent.find('.tsdag').removeClass('selected');
			dag.addClass('selected');

			var over = dag.attr('data-over').split(';');
			
			// selecteer tijd
			var slots = dag.attr('data-slots').split(';');
			var str = '<ul>';
			for(var tel=0; tel< slots.length; tel++) {
				var cl  = "";
				var cl2 = "";

				// over checken ...
				if (over.length==slots.length) {
					if (over[tel]<10) {
						cl2 = " almostSoldOut ";
					}
				}


				var ti = slots[tel];
				var p = ti.indexOf('|AV:');
				ti = ti.substr(0,p);


				if (tijd==ti) {
					cl = " selected "; 

					if (cl2!="") {
						// melding aanzetten.
						if (over[tel]==1) {
							$('.meldingOver').html("Voor dit tijdslot is nog maar 1 tickets over!");
						} else {
							$('.meldingOver').html("Voor dit tijdslot zijn nog maar "+over[tel]+" tickets over!");
						}
						$('.meldingOver').show();
					}

				}
				str = str +  '<li class="'+cl+cl2+'">' + ti + '</li>';
			}
			str = str +  '</ul>';
			
			// toon tijdsloten
			parent.find('.timeCntrInner').hide();
			parent.find('.timeCntrInner').html(str);
			parent.find('.timeCntrInner').fadeIn();

			// label footer
			setLabelTS(parent);
	}
	
	
	$('.tsclose').click(function(e){
		var parent = $(this).closest('.timeSlotCalender');
		e.preventDefault();
		
		if ($('[data-popup]').is(':visible')) {
			$('#popupBackground').fadeOut();
			$('[data-popup]').hide();
		} else {
			parent.hide();	
		}
		store.dispatch('closePopUp');
	});
	
	$('.tshome').click(function(e){
		var parent = $(this).closest('.timeSlotCalender');
		if ( $(this).attr('data-today')!="") {
			var date = $(this).attr('data-today').substr(0,10);
			
			// zoek de dag op
			var dag = parent.find('[data-date="'+date+'"]');
			var maandBlok = dag.closest('.tsblok');

			// zorg dat de maand actief is
			if (!maandBlok.is(':visible')) {
				parent.find('[data-volgnum]').hide();
				maandBlok.show();

				// label header
				var label = maandBlok.attr('data-label');
				parent.find('.tslabel').html(label);
			}
		}
		resetTsKeuze(parent);
		e.preventDefault();
	});
	
	$('.tsOK').click(function(){
		
		var isOK = false;
		
		// parent
		var parent = $(this).closest('.timeSlotCalender');
		
		// dag
		var dag = parent.find('.tsdag.selected').attr('data-date');
		var dagLabel = parent.find('.tsdag.selected').attr('data-label');

		// tijd
		var tijd = parent.find('.timeCntrInner > ul > li.selected').html();
		
		// Afhandelen!!
		if (tijd != undefined && dag != undefined) {
			
			isOK = true;
			
			// opslaan gekozen item
			parent.attr('data-end-slot',dag+" "+tijd);

			
		} else {
			
			// Uitzetten zetten
			if (parent.attr('data-onSelectLabel').length>0 && parent.attr('data-onSelectLabelDefault').length>0) {
				$('.'+parent.attr('data-onSelectLabel')).html(parent.attr('data-onSelectLabelDefault').val());
			}
					
		}
		
		
		// Custom select functie
		if (parent.attr('data-onSelectClass').length>0) {
			var fn = parent.attr('data-onSelectClass');
			if (fn=="slotToShop") {
				slotToShop(isOK,parent,dag+" "+tijd,dagLabel+" "+tijd);
			}
			if (fn=="slotToShopDetail") { // multiple
				slotToShopDetail(isOK,parent,dag+" "+tijd,dagLabel+" "+tijd);
			}
			if (fn=='boTicketSelectedTimeSlot') { // boxoffice
				slotToBoxOffice(isOK,parent,dag+" "+tijd,dagLabel+" "+tijd);				
			}
		}	

		// sluiten popup window
		if ($('[data-popup]').is(':visible')) {
			$('#popupBackground').fadeOut();
			$('[data-popup]').hide();
		} else {
			parent.hide();	
		}
		store.dispatch('closePopUp');
		

	});
	
},
//});


slotToShop: function(isOK,parent,slot,slotLabel) {
	parent = parent.closest('footer');
	
	if (isOK) {
		// gekozen slot laten zien
		parent.find('.slotToShopLabel').html(slotLabel);
		
		// alle knoppen aanzetten
		parent.find('.btn').each(function(){
			$(this).prop("disabled", false); 
		});
		
		// labels aanzetten 
		parent.find('div').each(function(){
			$(this).removeAttr('disabled');
		});

		// inputs (spaaractie) aanzetten
		parent.find('input').each(function(){
			$(this).removeAttr('disabled');
		});

		validateTimeSlotTicketTypes(parent,slot);

		// gekozen tijd zetten in rows voor de shops
		parent.find('.shopRow').attr('data-timeslot',slot);
	} else {
		
		
	}
},

// multiple shop
slotToShopDetail: function(isOK,parent,slot,slotLabel) {
	parent = parent.closest('.multiDetailOrderCntr');
	
	if (isOK) {
		// gekozen slot laten zien
		parent.find('.slotToShopLabel').html(slotLabel);
		
		// alle knoppen aanzetten
		parent.find('.btn').each(function(){
			$(this).prop("disabled", false); 
		});
		
		// labels aanzetten 
		parent.find('div').each(function(){
			$(this).removeAttr('disabled');
		});

		// inputs (spaaractie) aanzetten
		parent.find('input').each(function(){
			$(this).removeAttr('disabled');
		});

		validateTimeSlotTicketTypes(parent,slot);

		// gekozen tijd zetten in rows voor de shops
		parent.find('.shopRow').attr('data-timeslot',slot);
	} else {
		
		
	}
},


slotToBoxOffice: function(isOK,parent,slot,slotLabel) {
	parent = parent.closest('.boTicketEvent');
	
	if (isOK) {
		// gekozen slot laten zien
		// strip aantallen
		var lbl = slotLabel.substr(0,slotLabel.indexOf("|")-1);	
		var sl = slot.substr(0,slot.indexOf("|")-1);	
		parent.find('.boTicketSelectedTimeSlot > i').html(lbl);
		parent.find('.boTicketSelectedTimeSlot').attr('data-boTicketSlotSelected',1);
		parent.find('.boTicketSelectedTimeSlot').attr('data-boTicketSlot',sl);
		
		/*
		// alle knoppen aanzetten
		parent.find('.btn').each(function(){
			$(this).prop("disabled", false); 
		});
		
		// labels aanzetten 
		parent.find('div').each(function(){
			$(this).removeAttr('disabled');
		});
		
		// gekozen tijd zetten in rows voor de shops
		parent.find('.shopRow').attr('data-timeslot',slot);
		*/
	} else {
		
		
	}

},

validateTimeSlotTicketTypes: function(parent,slot) {

	slot = slot.substr(0,16);
	if (typeof arTSTT !== 'undefined') {

		// Custom foutmelding uitzetten (voor als NIET allle tickettypes aangezet worden,
		if (typeof customJavaErrorTicketType !== 'undefined') {
			parent.closest('footer').find(customJavaErrorTicketType).hide();
		}
		var allesAanTicketTypesAan = true;


		arTSTT.forEach(function(item){
			if (item.key==slot) {
				if (item.artt.length>0) {
					// Er zijn tickettypes of dit slot aktief!!

					// alle knoppen uitzetten, behalve het tijdslot.
					parent.find('.btn').each(function(){
						if ($(this).attr('data-popup-run') === undefined && !$(this).hasClass('tsOK')) {
							$(this).prop("disabled", true);
						}
					});

					// labels uitzetten
					parent.find('div').each(function(){
						$(this).prop('disabled',true);
					});

					// inputs (spaaractie) uitzetten
					parent.find('input').each(function(){
						$(this).prop('disabled',true);
					});



					// optie alleen inclusive (option = 1)
					if (item.tto==1) {
						
						for(var tel=0; tel < item.artt.length; tel++) {

							parent.find('[data-tickettype='+item.artt[tel]+']').each(function() {
								tsRegelAan(parent);
							});
						}
					}

					// optie alleen exclusive (option = 2)
					if (item.tto==2) {

						// loop alle tickettypes door....
						parent.find('[data-tickettype]').each(function(){
							var tt = $(this).attr('data-tickettype');
							var gev = false;
							for(var tel=0; tel < item.artt.length; tel++) {
								if (item.artt[tel]==tt) {
									gev = true;
								} else {
									allesAanTicketTypesAan = false;
								}
							}
							if (!gev) {
								tsRegelAan($(this));
							}
						});

					}

				}
			}
		});

		// custom error
		if (typeof customJavaErrorTicketType !== 'undefined') {
			if (!allesAanTicketTypesAan) {
				parent.closest('footer').find(customJavaErrorTicketType).show();
			} else {
				parent.closest('footer').find(customJavaErrorTicketType).hide();
			}
		}


	}



},

tsRegelAan: function(parent) {

	// alle knoppen aanzetten
	parent.find('.btn').each(function(){
		$(this).prop("disabled", false);
	});

	// labels aanzetten
	parent.find('div').each(function(){
		$(this).removeAttr('disabled');
	});

	// inputs (spaaractie) aanzetten
	parent.find('input').each(function(){
		$(this).removeAttr('disabled');
	});

}
}

export default slots;
