<template>
  <!-- Vlaggen om de taal van de website te selecteren -->
  <div @click.stop id="flags"  ref="dropdownContainer">
    <!-- Huidige vlag, klikt om de andere vlaggen weer te geven -->
    <img id="default-flag" class="default-flag" :src="flagUrl(currentlySetLanguage)" @click="openClose" />
    <!-- Andere vlaggen om uit te kiezen -->
    <div class="other-flags" :class="{ 'other-flags-active': areOtherFlagsActive }">
      <div v-for="(lang, index) in languages" :key="index" ref="setFlagRef">
        <div class="other-flag-wrapper">
          <div class="other-flag" @click="changeLanguage(lang)">
            <span>{{ lang.name }}</span>
            <img :data-id="lang.code" :src="flagUrl(lang.fileName)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, onBeforeUpdate } from "vue";
import { store } from '../store/index.js';

// Reactieve gegevens
//const currentlySetLanguage = ref((localStorage.getItem('localeLanguage') || 'nl-NL') + '.png');
//const currentlySetLanguage = computed(() => store.state.localeLanguage+'.png');
const currentlySetLanguage = computed({
  get: () => store.state.localeLanguage + ".png", // Haal de waarde op uit de store
  set: (value) => {
    // Commit de nieuwe waarde naar de store
    const languageWithoutExtension = value.replace(".png", ""); // Verwijder de extensie
    //store.commit("SET_LOCALE_LANGUAGE", languageWithoutExtension); // Mutatie aanroepen
    store.dispatch('registerLocaleLanguage', languageWithoutExtension);
  },
});


const languages = computed(() => store.state.languages);
const areOtherFlagsActive = ref(false);
const flagRefs = ref([]);
const dropdownContainer = ref(null);

// Functie om de vlaggen weer te geven of te verbergen
const openClose = () => {
  areOtherFlagsActive.value = !areOtherFlagsActive.value;
};

// Functie om de URL van de vlag te genereren
const flagUrl = (fileName) => {
  return new URL(`../assets/flags/${fileName}`, import.meta.url).href;
};

// Functie om de taal te wijzigen
const changeLanguage = (lang) => {
  // Haal de nieuwe taalcode op
  const newLanguageSet = lang.code;

  // Standaard naar Engels als de nieuwe taal niet kan worden opgehaald
  const newLanguageImage = flagUrl(lang.fileName);

  // Nieuwe taal opslaan in store
  store.dispatch('registerLocaleLanguage', newLanguageSet);

  // Nieuwe taal opslaan in localStorage
  localStorage.setItem('localeLanguage', newLanguageSet);

  // Nieuwe afbeelding instellen
  currentlySetLanguage.value = `${newLanguageSet}.png`;

  // Sluit de vlaggenselectie
  openClose();
};

// Functie om de referenties naar de vlaggen vast te leggen
const setFlagRef = (el) => {
  if (el) {
    flagRefs.value.push(el);
  }
};

// Voor de update, maak de referenties naar vlaggen leeg
onBeforeUpdate(() => {
  flagRefs.value = [];
});

// Lifecycle hooks
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

const handleClickOutside = (event) => {
    // Controleer of de klik buiten het component was
    if (dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
      areOtherFlagsActive.value = false; // Sluit het dropdown-menu
      /*
      setTimeout(() => {
        event.target.dispatchEvent(new Event('click', { bubbles: true }));
      }, 0);
       */
    }
};

</script>

<style scoped>
#flags {
  position: absolute;
  right: 25px;
  top: 25px;
}

#default-flag {
  position: relative;
  float: right;
  width: 40px;
  cursor: pointer;
  border-radius: 50px;
  background-color: white;
  border: 2px solid white;
}

.other-flags {
  margin-right: -48px;
  margin-top: 50px;
  position: relative;
  display: none;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  width: 140px;
  border: solid 1px rgb(221, 221, 221);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.other-flags-active {
  display: block;
}

.other-flag {
  padding: 8px;
  box-sizing: border-box;
  vertical-align: middle;
  height: 57px;
  clear: both;
}
.other-flag:hover {
  background-color: rgb(174, 213, 252);
}
.other-flag span {
  float: left;
  padding-top: 14px;
}

.other-flag img {
  width: 40px;
  float: right;
}
</style>
