<script setup>

  import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue';
  import Flag from "@/components/Flag.vue";
  import Alert from "@/components/Alert.vue";
  import {Functions} from "@/util/util.js";
  import {store} from "@/store/index";
  import {Selector} from "@/language/language.js";
  import ShoppingList from "@/components/Checkout/components/ShoppingList.vue";
  import ShoppingCart from "@/components/Checkout/views/ShoppingCart.vue";

  const state = store.state;
  const confirmActive = ref(false);
  const confirmTitle = ref('');
  const confirmText = ref('');
  const confirmType = ref('');
  const confirmTime = ref(null);
  const okButtonActive = ref(0);

  const alertActive = ref(false);
  const alertTitle = ref('');
  const alertText = ref('');
  const alertType = ref('');
  const alertTime = ref(null);



  const closePopUp = () => {
    const $body = document.querySelector('body');
    let scrollPosition = store.state.scrollPosition;
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
    store.dispatch('closePopUp');

    // Indien via directcart op clienetpopup, dan dit window sluiten
    const message = {
      type: 'close'
    };
    // TODO
    if (document.referrer !== null && document.referrer !== undefined && document.referrer !== '') {
      window.parent.postMessage(message, document.referrer);
    }
  }


  // Zorg ervoor dat je de functie met const declareert
  const translateString = (stringNotTranslated) => {
    var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated);
    return translatedString;
  };

  const goStepNext = () => {
    store.dispatch('changeStep', 'klantinformatie');
  }


  // Definieer de `closeAlert` functie
  const closeAlert = (x) => {

    confirmActive.value = false;
    confirmTitle.value = '';
    confirmText.value = '';
    confirmType.value = '';
    confirmTime.value = null;
    okButtonActive.value = 0;

  };


</script>

<template>
  <div class="MobileOverviewForm" v-if="state.isLoaded">
    <Alert :title="confirmTitle" :text="confirmText" :alertType="confirmType" :active="confirmActive" :time="confirmTime" :okButtonActive="okButtonActive" @confirmAnswer="onConfirmation" @closeAlert="closeAlert"></Alert>
    <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="alertActive" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="confirm" @closeAlert="closeAlert"></Alert>

    <div class="MobileOverviewFormHeader">
      <Flag v-once></Flag>
      <div class="MobileOverviewFormTitle">{{translateString('Shoppingcart')}}</div>
    </div>
    <div class="MobileOverviewFormBody">
      <div class="buttonWrapper">
        <button v-if="!state.isDirectOrder" class="btn btnWhite btnVerderWinkelen" v-on:click="closePopUp()">{{translateString('VerderWinkelen')}}</button>

        <button class="btn btnGreen nextStepBtn" v-on:click="goStepNext">{{translateString('Bestellen')}} <font-awesome-icon icon="caret-right" /></button>


      </div>

      <div class="lijn"></div>

      <ShoppingList></ShoppingList>


    </div>
  </div>


</template>

<style scoped>

.MobileOverviewForm{
  position: relative;
  left: 0;
  text-align: left;
}

.MobileOverviewFormHeader {
  min-height: 100px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  position: relative;
  background-color: whitesmoke;
  border-top-left-radius: 10px;
}

.MobileOverviewFormTitle {
  font-weight: bold;
  font-size: 28px;
  font-family: 'Nunito', sans-serif;
  max-width: 550px;
  color: rgb(65, 65, 65);
}

.MobileOverviewFormBody {
  padding: 20px 20px 20px 20px;
  font-family: 'Didact Gothic', sans-serif;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}

.buttonWrapper {
  display: flex;
}

.btnNuBetalen > img {
  position: absolute;
  height:auto;
  left:10px;
  top:10px;
}

.nextStepBtn {
  margin-left:auto;

}




</style>