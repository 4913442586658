<template>
        <ul class="legenda">
            <li>
                <div class="legenda_label">
                    <div class="legenda_blok" :style="{ background: 'white' }"/> 
                    Beschikbaar
                </div>
            </li>
            <li>
                <div class="legenda_label">
                    <div class="legenda_blok"  :style="{ background: 'crimson' }"/> 
                    Verkocht 
                </div>
            </li>
            <li>
                <div class="legenda_label">
                    <div class="legenda_blok"  :style="{ background: 'gray' }"/> 
                    Niet beschikbaar 
                </div>
            </li>
            <li>
                <div class="legenda_label">
                    <div class="legenda_blok"  :style="{ background: 'orange' }"/> 
                    Gereserveerd 
                </div>
            </li>
            <li>
                <div class="legenda_label">
                    <div class="legenda_blok"  :style="{ background: 'green' }"/> 
                    Door u geselecteerd
                </div>
            </li>
        </ul>
        <div style="clear:both;"></div>
        <ul class="rangen">
            <li v-for="rank in state.ranks.items" :key="rank">
                <div class="rangen_label">
                    <div class="rangen_blok"  :style="{ borderTopColor: rank.color }"/> 
                    {{rank.displayName}}
                </div>
            </li>
        </ul>

</template>

<script>
import {store} from '../store/index.js';
export default {
    data(){
        return{
            state: store.state,
        }
    }
}
</script>

<style>
.legenda{
    color: white;
    font-size: 14px;
    background: transparent;
    margin: 20px 20px!important;
    line-height: 18px;
    display: block;
}
.legenda li {
    float:left;
    margin-left:15px;
    list-style-type: none;
}
.legenda_blok{
    margin: 4px 5px 0px 0px;
    width: 12px;
    height:12px;
    float: left;
}

.rangen {
    display:block;
    color: white;
    margin:5px 20px!important;
}
.rangen li {
    float:left;
    margin-left:15px;
}
.rangen_blok  {
    height:14px;
    width:14px;
    border-radius: 0em 0em 0.3em 0.3em;
    float:left;
    background-color: white;
    border-top-width: 5px;
    border-top-style: solid;
    margin-top:3px;
    margin-right:5px;
}

</style>