<script setup>

  import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue';
  import Flag from "@/components/Flag.vue";
  import Alert from "@/components/Alert.vue";
  import {Functions} from "@/util/util.js";
  import {store} from "@/store/index";
  import {Selector} from "@/language/language.js";
  import ShoppingList from "@/components/Checkout/components/ShoppingList.vue";

  const state = store.state;
  const confirmActive = ref(false);
  const confirmTitle = ref('');
  const confirmText = ref('');
  const confirmType = ref('');
  const confirmTime = ref(null);
  const okButtonActive = ref(0);

  const alertActive = ref(false);
  const alertTitle = ref('');
  const alertText = ref('');
  const alertType = ref('');
  const alertTime = ref(null);




  // Zorg ervoor dat je de functie met const declareert
  const translateString = (stringNotTranslated) => {
    var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated);
    return translatedString;
  };

  const goStepBackCustomer = () => {
    store.dispatch('changeStep', 'klantinformatie');
  }

  const goStepBackPayment = () => {
    store.dispatch('changeStep', 'betalen');
  }


  const goToConfirm = () => {

    if (state.cart.totalPrice>=0) {

      // Alert zetten om te vragen voor confirmation.
      confirmTitle.value = Selector.SelectLanguageStringFromLangJSON('Alert', 'BevestigBestelling');
      confirmText.value = Selector.SelectLanguageStringFromLangJSON('Alert', 'WeetJeZekerDatJeNuWiltBetalen');
      confirmType.value = 4;
      confirmActive.value = true;
      confirmTime.value = null;

    }

  }

  // Definieer de `closeAlert` functie
  const closeAlert = (x) => {

    confirmActive.value = false;
    confirmTitle.value = '';
    confirmText.value = '';
    confirmType.value = '';
    confirmTime.value = null;
    okButtonActive.value = 0;

  };

  const onConfirmation = (answer) => {

      // Let op!! dezelfde functie staat er 2x in. Hier en bij paymentForm.vue
      confirmActive.value = false;

      if (answer) {

        alertTitle.value = '';
        alertType.value = '';
        alertText.value = Selector.SelectLanguageStringFromLangJSON('Alert', 'BestellingVerwerken')
        alertActive.value = true;

        //this.timesThePayButtonIsClicked = this.timesThePayButtonIsClicked + 1;
        var accId = store.state.cart.bestelItems[0].accountId;
        store.dispatch('startPayment', accId).then(response => {

          if (response.status === "OK") {

            // Weet niet nog wa tik ermee moet....
            var betaaldBedrag = response.totaalBedrag;
            var paymentStatus = response.paymentStatus;

            // closeAlert(false);

          } else if (response.status === "ERROR") {


            alertTitle.value = Selector.SelectLanguageStringFromLangJSON('Alert', 'ErIsIetsMisgegaan');
            alertText.value = response.errorMsg;
            alertType.value = 2;
            alertActive.value = true;
            okButtonActive.value = 1;
            if (response.errorLevel === "SEVERE") {
              // Op de achtergrond

              store.dispatch('setError', this.selectedPaymentMethod);
              store.dispatch('setError', {
                error1: alertTitle.value,
                error2: alertText.value,
                errorTimerMilliseconds: 10000
              })


            }

          }

        });

      }

    }



</script>

<template>
  <div class="MobileOverviewForm" v-if="state.isLoaded">
    <Alert :title="confirmTitle" :text="confirmText" :alertType="confirmType" :active="confirmActive" :time="confirmTime" :okButtonActive="okButtonActive" @confirmAnswer="onConfirmation" @closeAlert="closeAlert"></Alert>
    <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="alertActive" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="confirm" @closeAlert="closeAlert"></Alert>


    <div class="MobileOverviewFormHeader">
      <Flag v-once></Flag>
      <div class="MobileOverviewFormTitle">{{translateString('Bevestigen')}}</div>
    </div>
    <div class="MobileOverviewFormBody">
      <div class="buttonWrapper">
        <button class="btn btnWhite stepBackBtn" v-if="state.cart.totalPrice>0"   v-on:click="goStepBackPayment"><font-awesome-icon icon="caret-left" /> {{translateString('Vorige')}}</button>
        <button class="btn btnWhite stepBackBtn" v-if="state.cart.totalPrice==0" v-on:click="goStepBackCustomer"><font-awesome-icon icon="caret-left" /> {{translateString('Vorige')}}</button>

        <button v-if="state.cart.totalPrice > 0 && state.step == 'overzicht' && state.cart.bestelItems.length > 0"
                class="btn btnGreen btnNuBetalen" v-on:click="goToConfirm()">
          <img v-if="state.selectedPaymentMethod != {}" class="imageSelectedPaymentMethod" :src="state.selectedPaymentMethod.Image" />
          {{translateString('NuBetalen')}}
        </button>
        <button v-if="state.cart.totalPrice == 0 && state.step == 'overzicht' && state.cart.bestelItems.length > 0"
                class="btn btnGreen btnAfronden" v-on:click="goToConfirm()">
          {{translateString('BestellingAfronden')}}
        </button>

      </div>

      <div class="lijn"></div>

      <ShoppingList></ShoppingList>


    </div>
  </div>


</template>

<style scoped>

.MobileOverviewForm{
  position: relative;
  left: 0;
  text-align: left;
}

.MobileOverviewFormHeader {
  min-height: 100px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  position: relative;
  background-color: whitesmoke;
  border-top-left-radius: 10px;
}

.MobileOverviewFormTitle {
  font-weight: bold;
  font-size: 28px;
  font-family: 'Nunito', sans-serif;
  max-width: 550px;
  color: rgb(65, 65, 65);
}

.MobileOverviewFormBody {
  padding: 20px 20px 20px 20px;
  font-family: 'Didact Gothic', sans-serif;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}

.buttonWrapper {
  display: flex;
}

.btnNuBetalen > img {
  position: absolute;
  height:auto;
  left:10px;
  top:10px;
}

.btnNuBetalen {
  position:relative;
  margin-left:auto;
  min-width: auto!important;
  width:auto;
  padding-left:50px;
  padding-top:8px;
  padding-right:20px;
  height:45px;
}
.btnAfronden {
  margin-left:auto;

}




</style>