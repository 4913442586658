<template>
    <div @click.stop class="go-back">
        <div class="go-back-wrapper">
            <button class="go-back-btn" v-on:click="goBack"><span></span></button>
        </div>
    </div>
</template>

<script>
import {store} from '../store/index.js';
export default {
    methods: {
        goBack() {
            // ervoor zorgen dat error weggehaald wordt als je een pagina teruggaat
            store.dispatch('removeError');

            if(window.location.href.includes('/personalshop')) {
                window.location.replace('https://'+store.state.shopID+'.ticketview.shop')
                //return this.$router.push('/');
            } else {
                // return this.$router.push("/");
                return this.$router.go(-1);
            }
        }
    },
}
</script>

<style scoped>
.go-back-wrapper{
    position: absolute;
    left: 25px;
    top: 25px;
}

.go-back-wrapper .go-back-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50px;
    background: #005b8c;
    padding: 0px;
    outline: none;
    position: relative;
    z-index: 1;
    float: right;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.3 ease-in-out;
}

.go-back-wrapper.active .go-back-btn {
    width: 34px;
    height: 34px;
    margin: 3px;
    border-radius: 30px;
}

.go-back-wrapper .go-back-btn span {
    width: 25px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    /*transform: rotate(45deg);*/
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}
.go-back-wrapper.active .go-back-btn span {
    width: 17px;
    height: 19px;
    transform: rotate(-45deg);
}
.go-back-wrapper .go-back-btn span::before, .go-back-wrapper .go-back-btn span::after {
    position: absolute;
    content:'';
}

/* zorgt ervoor dat als je over de knop hovert, de pijl iets naar links gaat */
.go-back-wrapper .go-back-btn span:hover::before,
.go-back-wrapper .go-back-btn span:hover::after {
    left:-2px;
}

/* voorkant (driehoek) vam de pijl */
.go-back-wrapper .go-back-btn span::before {
    /*width: 3px;
    height: 14px;
    left: 2px;
    top: 10px;
    border-radius: 2px;
    background: white;*/

    border-color: white;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    display: block;
    height: 8px;
    left: 5px;
    position: absolute;
    width: 8px;

    margin-top: -4px;
    top: 48%;

    transition: left 0.1s;

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
/* de rest van de pijl */
.go-back-wrapper .go-back-btn span::after {
    /*width: 11px;
    height: 11px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    border: 3px solid white;*/
    
    background: white;
    content: '';
    display: block;
    height: 2px;
    left: 6px;
    position: absolute;
    top: 48%;
    width: 14px;

    transition: left 0.1s;
}

</style>