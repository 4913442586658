<template>
    <div class="shopNotExist">
        <div class="headerShopNotExist">
        </div> 
        <div class="contentWrapper">
                <div class="contentContainer">         
                    <img class="imgSpacemen" src="@/assets/404-pagina-spacemen.png" alt="">
                <div class="title">Houston we have a problem...</div> 
                <div class="text">{{ this.getError() }}</div>
            </div>  
        </div>
    </div>
</template>

<script>
import { store } from '@/store/index.js';
export default {
    name: 'ShopDoesNotExist',
    data() {
        return {
            error: ''
        }
    },
    methods: {
        getError() {
          if (store.state.error.error1 != undefined && store.state.error.error1!="") {
            return store.state.error.error1;
          } else {
            return "Shop or page does not exist.";
          }
        }
    }
}
</script>

<style scoped>
.shopNotExist {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}

.contentWrapper {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}

.headerShopNotExist {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}

.title {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 35px;
    margin-bottom:15px;
    font-size: 40px;
}

.contentContainer {
    margin-top: 60px;
    width: 1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}

.text {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 25px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      margin-bottom: 35px;
  }

.imgSpacemen {
    height: 300px;
    width: 300px;
}

@media(max-width: 1050px) {
    .contentContainer {
       width: 90%;
    }
}
</style>