<template>
<div id="mainWrapper" v-bind:class="isClientShop"  v-on:click="checkCloseClient">
       <CookieMessage :id="state.status.accountID.toString()" :account-name="state.status.accountName" v-if="state.isLoaded && state.status.shopCookieRequired==1" />
       <ErrorMessage/>
        <WarningMessage/>
        <!--<div id="nav">
          <router-link to="/">Home</router-link>
          <router-link to="/test">Test</router-link>
        </div>-->
        <router-view/>
      <div id="preloader"  v-bind:class="isClientShop">
        <div class="preLoaderCntr">
          <img src="@/assets/Loader-white.gif" />
          <div id="preloader-text">Loading...</div>
        </div>
      </div>
</div>
</template>

<script>
import {Functions} from './util/util.js';
import ErrorMessage from './components/ErrorMessage.vue';
import WarningMessage from './components/WarningMessage.vue';
import { store } from './store/index.js';
import $ from 'jquery'
import router from "@/router/index.js";
import CookieMessage from "@/components/CookieMessage.vue";
import {Selector} from '@/language/language.js';




export default {
  components: {
      CookieMessage,
      ErrorMessage,
      WarningMessage
  },
  setup() {
    return {
      state: store.state
    }
  },
  methods: {
    checkCloseClient(event) {
      if(this.isClientShop) {
        if (event.target === event.currentTarget) {
          const message = {
            type: 'close'
          };
          window.parent.postMessage(message, document.referrer);
        }
      }
    }
  },
  computed: {
    isClientShop() {
        if (window.location.href.includes('/clientshop') || window.location.href.includes('/directcart')) {
          return 'transparentBackground';
        } else {
          return ""
        }
    }
  },
  created() {
    // functies (getBrowserLanguage & defineLanguage) verplaatst naar util.js
    var browserLang;
    var localeLang;


    if(localStorage.getItem("browserLanguage") === null) {
      browserLang = Functions.getBrowserLanguage();
      localStorage.setItem("browserLanguage", browserLang);
      store.dispatch('registerBrowserLanguage', browserLang); 
    } else {
      browserLang = localStorage.getItem("browserLanguage");
      store.dispatch('registerBrowserLanguage', browserLang); 
    }

    if(localStorage.getItem("localeLanguage") === null) {
      // als er nog geen localeLanguage bestaat, pak dan de browserLanguage
      localeLang = Functions.getBrowserLanguage();
      localStorage.setItem("localeLanguage", localeLang);    
      store.dispatch('registerLocaleLanguage', localeLang);  
    } else {
      localeLang = localStorage.getItem("localeLanguage");
      localStorage.setItem("localeLanguage", localeLang);    
      store.dispatch('registerLocaleLanguage', localeLang);  
    }
    
    // functie (getShopID) verplaatst naar util.js
    var shopID;
    shopID = Functions.getShopID();
    store.dispatch('registerShopID', shopID);

    // via store.dispatch kan een actie worden aangeroepen in store/index.js
    //store.dispatch('loadStatus');

  },
  mounted() {

    const updateIsMobile = () => {
      store.dispatch('setIsMobileCart');
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    this.updateIsMobile = updateIsMobile;

    // eventuele ShopNumber en trackerID zetten.
    store.dispatch('saveShopNumAndTrackerID', window.location.pathname);

    // Origineel path waarop men is binnengekomen.
    localStorage.setItem('entrancePath', window.location.pathname);


    let url = window.location.href;
    let params = (new URL(url)).searchParams;


    // Indien vie /event/ binnen gekomen, dan dit onthiuden
    if(window.location.href.includes('/event/')) {
      localStorage.setItem('previousPath', document.referrer);
      store.dispatch('saveDirectEvent', true);
    }


    if(params.get('o')) {
      // als er een shopReservationKey aanwezig is, dan loadCart
      store.dispatch('loadCart');
    } else if(window.location.href.includes('/processpayment') || window.location.href.includes('/directorder') || window.location.href.includes('/orderresult') || window.location.href.includes('/personalshop')) {
      // Niks
    } else if(window.location.href.includes('/clientshop') || window.location.href.includes('/directcart')) {
      store.dispatch('saveClientPage', true);
      store.dispatch('initShop').then(
          response => {
            // niks
          }
      ).catch(
          error => {
            console.error(error);
          }
      );

    } else {
      // anders initShop
      store.dispatch('initShop').then(
          response => {
             // niks
          }
      ).catch(
          error => {
            console.error(error);
          }
      );
    }

    // Indien pagina closed, dan altijd redirecten naar de homepage (hij komt hier alleen bij eht starten OF een refresh van de site)
    if(window.location.href.includes('/closed')) {
      router.push('/');
    }




    if(window.location.href.includes('/processpayment')) {
      $("#preloader").fadeOut("slow");
    }
  }
}
</script>

<style>
@import "./styles/general.css";

/*********** BEGIN CSS RESET *************/
/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*********** END CSS RESET *************/

* {
   box-sizing: border-box;
  }
  
html, body, #mainWrapper, #app {
  width: 100%;  
  /*overflow-x:hidden; */
  box-sizing: border-box;
  height:100vh;
  position: relative;
  background-color: transparent;
}
#mainWrapper {
  background-color: white;
}

.transparentBackground {
  background-color: transparent!important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: 'Didact Gothic', sans-serif;
  font-size:16px;
}

body {
  padding: 0;
  margin: 0;
}


/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/

#preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
}

.preLoaderCntr {
  position:absolute;
  left:50%;
  width: 200px;
  height: 200px;
  margin-left: -75px;
  top:30%;
  background-color: rgba(0,0,0,0.9);
  border-radius: 12px;
}

#preloader-text {
  color: white;
  font-size: 28px;
  text-align: center;
  margin-top: 20px;
}

.preLoaderCntr > img {
  margin-top: 20px;
  margin-left:10px;
  height:100px;
}


.MobileShoppingList {
  position: fixed;
  bottom:0;
}

</style>