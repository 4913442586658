<template>
    <div class="alert" id="alert" v-if="active">
        <div class="alert-mask" :class="isClientShop" v-on:click="closeAlert()">
            <div class="alert-wrapper">
                <div class="alert-container" v-on:click.stop="">
                    <div v-if="alertType == '' || alertType == 0">
                        <img class="loadingGif" src="@/assets/Loader.gif" />
                        <div class="alertText">{{text}}</div>
                    </div>
                    <div v-else>
                        <img v-if="alertType == 1" class="okIcon" src="@/assets/icons/ok-icon.svg" />                         
                        <img v-else-if="alertType == 2" class="okIcon" src="@/assets/icons/error-icon.svg" />        
                        <img v-else-if="alertType == 3" class="okIcon" src="@/assets/icons/warning-icon.svg" />
                        <img v-else-if="alertType == 4" class="okIcon" src="@/assets/icons/questionmark-icon.svg" />

                      <div class="alertTitle">{{title}}</div>
                        <div style="max-height: 500px; overflow-y: auto;" class="alertText" v-html="text"></div>

                        <div>                   
                            <button v-if="alertType == 1 && okButtonActive == 1" v-on:click="closeAlert" class="btn btnGreen btnCloseAlert">OK</button>
                            <button v-else-if="alertType == 2 && okButtonActive == 1" v-on:click="closeAlert" class="btn btnBlue btnCloseAlert">OK</button>

                            <div v-else-if="alertType == 3 || alertType == 4" style="display: flex; gap:20px; align-items: center; justify-content: center">
                                <button v-on:click="returnAnswerToParent(false)" class="btn btnBlue btnConfirmNo">{{translateString('Nee')}}</button>
                                <button v-on:click="returnAnswerToParent(true)" class="btn btnGreen btnConfirmYes">{{translateString('Ja')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Selector} from '@/language/language.js';
import {store} from '@/store/index.js';
export default {
    setup() {
        return {
            state: store.state
        }
    },
    props: {
        title: {
            default: ''
        },
        text: {
            default: () => this.translateString('BeschikbaarheidControleren')
        },
        alertType: {
            default: ''
        },
        active: {
            default: false
        },
        time: {
            default: null
        },
        okButtonActive: {
            default: 0
        }
    },
    methods: {
        closeAlert() {
            if(this.alertType != '') {
                this.$emit('closeAlert', false);
            }
        },
        returnAnswerToParent(answer) {
            this.$emit('confirmAnswer', answer);
        },
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('Alert', stringNotTranslated)
            return translatedString;
        },
    },
   computed: {
        isClientShop() {
          // alert('check' + this.state.isClientPage);
            if(this.state.isClientPage) {
                return 'clientPage';
            } else {
                return '';
            }
        }
    },
    watch: {
        time(val, oldVal) {
            if(val != null) {
                setTimeout(this.closeAlert,this.time);
            }
        }
    }
}
</script>

<style scoped>
.alert-mask {
    position: fixed;
    top: -30px;
    left: 0;
    width: 100%;
    height: 110%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    overflow-y: scroll;
    text-align: center;
}

.clientPage {
    background-color: transparent!important;

}

.alert-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-top: 45px;
}

.alert-container {
    width: 500px;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: absolute;
    font-family: 'Didact Gothic', sans-serif;
}

.loadingGif {
    width: 100px;
    margin: 80px auto;
}

.alertTitle {
    font-size: 22px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(65, 65, 65);
} 

.alertText {
    margin-top: 20px;
}

.okIcon {
    width: 100px;
    margin: 20px auto;
}

.btnCloseAlert, .btnConfirmYes {
    margin-top: 50px;
}
.btnConfirmNo {
    margin-top: 50px;
    margin-right: 20px;
}

@media(max-width: 550px) {
    .alert-container {
        margin: 0 auto;
        width: 90%;
    }   
} 
</style>