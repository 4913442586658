<template>
    <div class="paymentForm" v-if="state.isLoaded">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="alertActive" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="confirm" @closeAlert="closeAlert"></Alert>
        <Alert :title="confirmTitle" :text="confirmText" :alertType="confirmType" :active="confirmActive" :time="confirmTime" :okButtonActive="okButtonActive" @confirmAnswer="onConfirmPayment" @closeAlert="closeAlert"></Alert>

        <div class="paymentFormHeader">
            <Flag v-once></Flag>
            <div class="paymentFormTitle">{{translateString('Betalen')}}</div>
        </div>
        <div class="paymentFormBody">
            <div class="buttonWrapper">
                <button class="btn btnWhite stepBackBtn" v-on:click="goStepBack"><font-awesome-icon icon="caret-left" /> {{translateString('Vorige')}}</button>
                <!-- <button v-if="!state.isDirectOrder && !state.isDirectCart" class="btn btnWhite btnVerderWinkelen" v-on:click="closePopUp()">{{translateString('VerderWinkelen')}}</button> -->

                <button v-if="state.cart.totalPrice > 0 && state.step == 'betalen' && state.cart.bestelItems.length > 0 && !state.isMobileCart"
                    class="btn btnGreen btnNuBetalen" v-on:click="goToNextStep()">
                    <img v-if="state.selectedPaymentMethod != {}" class="imageSelectedPaymentMethod" :src="state.selectedPaymentMethod.Image" />
                    {{translateString('NuBetalen')}}
                </button>

                  <button v-if="state.cart.totalPrice > 0 && state.step == 'betalen' && state.cart.bestelItems.length > 0 && state.isMobileCart" class="btn btnGreen nextStepBtn rightAlignBtnDirectOrder" v-on:click="goToConfirm()">
                    <label style="padding-right:5px;">{{translateString('Besteloverzicht')}}</label>
                    <font-awesome-icon icon="caret-right" />
                  </button>


            </div>

            <div class="lijn"></div>

            <div class="paymentMethodListWrapper">
                <div class="paymentMethodList">
                    <div  v-for="(paymentMethod) in paymentMethodsFromStore" v-bind:key="paymentMethod.Key">             
                        <PaymentMethod :name="paymentMethod.Name"
                            :paymentKey="paymentMethod.Key"
                            :image="paymentMethod.Image"
                            :issuers="paymentMethod.issuers"
                            :costDisplay="paymentMethod.CostDisplay" 
                            :isSelected="selectedPaymentMethod.Key == paymentMethod.Key"
                            @changeIssuer="changeIssuer"
                            v-on:click="selectPaymentMethodToggle(paymentMethod.Key)"></PaymentMethod>
                    </div>
                </div>
            </div>
            
        </div>        
    </div>
</template>

<script>
import Flag from '../../Flag.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {store} from '@/store/index.js';
import {Selector} from '@/language/language.js';
import {Functions} from '@/util/util';
import PaymentMethod from '../components/PaymentMethod.vue';
import Alert from '@/components/Alert.vue';

export default {
    name: 'CustomerForm',
    data() {
        return {
            state: store.state,
            alertTitle: '',
            alertText: '',
            alertType: '',
            alertTime: null,
            alertActive: false,
            answer: null,
            okButtonActive: 0,
            confirm: 0,

            // confirm alert
            confirmTitle: '',
            confirmText: '',
            confirmType: '',
            confirmTime: null,
            confirmActive: false,


          selectedPaymentMethod: {
                CostDisplay: '',
                Key: '',
                Name: '',
                Image: '',
                issuers: [],
                selectedIssuer: ""
            },
            selectedPaymentMethodImage: undefined
        }
    },
    components: {
        Flag,
        Alert,
        PaymentMethod
    },
    methods: {
       changeIssuer(issuer) {

             // Extra opslaan selecvtedpayment ivm andere issuer
             Object.keys(this.paymentMethodsFromStore).forEach(key => {
               var payment = this.paymentMethodsFromStore[key];
               if(payment.Key == this.selectedPaymentMethod.Key) {
                 this.selectedPaymentMethod = payment; // updaten met issuer
                 store.dispatch('setSelectedPaymentMethod', this.selectedPaymentMethod);
               }
             })



       },
        getIssuerFromSelectedMethod(paymentKey) {

          for (const [key, method] of Object.entries(store.state.paymentMethods)) {
            if (key === paymentKey) {
              return method.selectedIssuer;
            }
          }

          return "";
        },
        onConfirmPayment(answer) {

         // Let op!! dezelfde functie staat er 2x in. Hier en bij MobileOverviewContainer.vue
          this.confirmActive = false;

          if (answer) {

            this.alertTitle = '';
            this.alertType = '';
            this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'BestellingVerwerken')
            this.alertActive = true;

            this.timesThePayButtonIsClicked = this.timesThePayButtonIsClicked + 1;
            var accId = store.state.cart.bestelItems[0].accountId;
            store.dispatch('startPayment', accId).then(response => {

              if (response.status === "OK") {

                // Weet niet nog wa tik ermee moet....
                var betaaldBedrag = response.totaalBedrag;
                var paymentStatus = response.paymentStatus;

                // closeAlert(false);

              } else if (response.status === "ERROR") {


                this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'ErIsIetsMisgegaan');
                this.alertText = response.errorMsg;
                this.alertType = 2;
                this.alertActive = true;
                this.okButtonActive = 1;
                if (response.errorLevel === "SEVERE") {
                  // Op de achtergrond

                  store.dispatch('setError', this.selectedPaymentMethod);
                  store.dispatch('setError', {
                    error1: this.alertTitle,
                    error2: this.alertText,
                    errorTimerMilliseconds: 10000
                  })


                }

              }

            });

          }

        },

        goToConfirm() {

          // Check  bank en issuer
          var check = this.checkIfSelectedPaymentIsCorrect(); // 1=ok, 2=moet nog kiezen, 3=issuer nog kiezen
          if (check==1) { // is ok

            if (this.state.cart.totalPrice>0) {
              store.dispatch('changeStep', 'overzicht');
            }

          } else if (check==2) { // bank kiezen

            this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'Oeps')
            this.alertType = 2;
            this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'JeMoetNogEenBetaalmethodeKiezen');
            this.alertActive = true;
            this.okButtonActive = 1;

          } else if (check==3) { // issuer nog kiezen

            this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'Oeps');
            this.alertType = 2;
            this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'JeMoetNogEenBankKiezen');
            this.alertActive = true;
            this.okButtonActive = 1;

          }

        },


        goToNextStep() {

            // Check  bank en issuer
            var check = this.checkIfSelectedPaymentIsCorrect(); // 1=ok, 2=moet nog kiezen, 3=issuer nog kiezen
            if (check==1) { // is ok

                if (this.state.cart.totalPrice>0) {

                  // Alert zetten om te vragen voor confirmation.
                  this.confirmTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'BevestigBestelling');
                  this.confirmText = Selector.SelectLanguageStringFromLangJSON('Alert', 'WeetJeZekerDatJeNuWiltBetalen');
                  this.confirmType = 4;
                  this.confirmActive = true;
                  this.confirmTime = null;

                }

            } else if (check==2) { // bank kiezen

                this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'Oeps')
                this.alertType = 2;
                this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'JeMoetNogEenBetaalmethodeKiezen');
                this.alertActive = true;
                this.okButtonActive = 1;

            } else if (check==3) { // issuer nog kiezen

                this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'Oeps');
                this.alertType = 2;
                this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'JeMoetNogEenBankKiezen');
                this.alertActive = true;
                this.okButtonActive = 1;


            }


        },
        goStepBack() {
            store.dispatch('changeStep', 'klantinformatie');
        },
        checkIfSelectedPaymentIsCorrect() {
            // return isCorrect -> 1: is inderdaad correct / 2: Nog geen paymentMethod geselecteerd / 3: Nog geen issuer geselecteerd
            var check =1;

            if(this.state.cart.method == '') {
                check = 2;
            } else {   
                Object.keys(this.state.paymentMethods).forEach(key => {
                    var payment = this.state.paymentMethods[key];
                    if(payment.Key == this.state.selectedPaymentMethod.Key) {
                        if(payment.issuers.length > 0){

                          if (this.state.selectedPaymentMethod.selectedIssuer === '') {
                            check = 3;
                            return;
                          }

                        }
                    }
                })
            }
            return check;
        }, 
        setAnswerFromAlert(answer) {
            this.answer = answer
        },
        closeAlert(close) {
            this.alertActive = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        },               
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated)
            return translatedString;
        },
        selectPaymentMethodToggle(chosenKey) {
            if(this.selectedPaymentMethod == {}) {
                this.selectedPaymentMethod = { CostDisplay: '',Key: '',Name: '',Image: '',issuers: [], selectedIssuer: ""};

                store.dispatch('changeMethodAndIssuerCart', {method: '', issuer: ''}) 

                store.dispatch('setSelectedPaymentMethod', this.selectedPaymentMethod)
            } else {
                var paymentMethod = {};       
                
                Object.keys(this.paymentMethodsFromStore).forEach(key => {
                    var payment = this.paymentMethodsFromStore[key];
                    if(payment.Key == chosenKey) {
                        paymentMethod = payment;
                    }
                })
                this.selectedPaymentMethod = paymentMethod;


                // opslaan
                store.dispatch('setSelectedPaymentMethod', paymentMethod)

                //store.dispatch('changeMethodAndIssuerCart', {method: paymentMethod.Key, issuer: paymentMethod.selectedIssuer})
            }
        },
        closePopUp() {
            const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);

            store.dispatch('closePopUp');
        }
    },
    computed: {
        paymentMethodsFromStore() {
            return store.state.paymentMethods;
        },
    },
    created() {
        var selectedPaymentMethodFromLocalStorage = JSON.parse(localStorage.getItem('selectedPaymentMethod'))


        if( selectedPaymentMethodFromLocalStorage && selectedPaymentMethodFromLocalStorage != { CostDisplay: '',Key: '',Name: '',Image: '',issuers: [] }) {
            this.selectedPaymentMethod = selectedPaymentMethodFromLocalStorage;
            store.dispatch('setSelectedPaymentMethod', selectedPaymentMethodFromLocalStorage)
        } else {
            var language = store.state.localeLanguage

            if (store.state.paymentMethodDefault !== undefined && store.state.paymentMethodDefault !== "") {
              this.selectPaymentMethodToggle(store.state.paymentMethodDefault);

            } else if(language == "nl-NL") {
                this.selectPaymentMethodToggle('ideal')
            }
            else if(language == "nl-BE") {
                this.selectPaymentMethodToggle('bancontact')
            }
            else {
                this.selectPaymentMethodToggle(store.state.paymentMethodDefault)
            }         
        }
    },
    watch: {
        selectedPaymentMethod(val, oldVal) {
            return this.selectedPaymentMethod;
        },
    },
}
</script>

<style scoped>
.paymentForm{
    position: relative;
    left: 0;
    text-align: left;
}

.paymentFormHeader {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
} 

.paymentFormTitle {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}

.paymentFormBody {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif; 
}
.paymentMethodListWrapper {
    margin-top: 30px;
    flex-shrink: 0;
    flex-grow: 1; 
}
.paymentMethodList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    max-width:auto;
    margin-top:0px;
    justify-items: flex-start;
    align-items: center;
}

.btnVerderWinkelen {
    margin-left: 10px;
}

.buttonWrapper {
    display: flex;
}

.btnNuBetalen > img {
    position: absolute;
    height:auto;
    left:10px;
    top:10px;
}

.btnNuBetalen {
    position:relative;
    margin-left:auto;
    min-width: auto!important;
    width:auto;
    padding-left:50px;
    padding-top:8px;
    padding-right:20px;
    height:45px;
}


.btnOverzichtCntr {
  display: flex;
  justify-content: center; /* Centreer de button horizontaal */
  align-items: center; /* Centreer de button verticaal (optioneel) */
  height: 100%; /* Zorg ervoor dat de container de volledige hoogte inneemt */
}

.btnOverzicht {
  display: flex;
  justify-content: center; /* Centreer de inhoud van de button */
  align-items: center; /* Centreer de inhoud van de button verticaal */
  background-color: green;
  padding: 10px 20px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}
.nextStepBtn {
  margin-left:auto;
}

.image {
    width: 100px;
    height: 100px;
    border: 1px solid green;
}

@media(max-width: 1120px) {
    .paymentFormHeader {
        border-top-right-radius: 10px;
    }
    .paymentFormBody {
        padding: 20px 20px 20px 20px;
    }
}
@media(max-width: 450px) {
    .stepBackBtn {
        margin-right: 10px;
    }
}
</style>